import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  TextInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin'
import FilterComponent from '../../components/Filter'
import { CustomS3AvatarField } from '../../components/CustomAvatarField'
import { ROLES } from '../../components/constants'
import ChannelStatusField from '../../components/ChannelStatusField'

const ChannelFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
    <ReferenceInput
      label="Podcaster"
      source="podcasterId"
      reference="podcasters"
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
  </FilterComponent>
)

const ChannelList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<ChannelFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source="name" label="Channel name" />
        <CustomS3AvatarField source="coverImageUrl" />
        <ReferenceField
          source="podcasterId"
          reference="podcasters"
          label="Podcaster">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="publishDate" label="Date Publish" />
        <ReferenceField
          label="Published by"
          source="publishedBy"
          reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updatedAt" label="Last update" />
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ChannelStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ChannelList
