import React from 'react'
import { List, Datagrid, TextField, EditButton, TextInput } from 'react-admin'
import FilterComponent from '../../components/Filter'

const VoiceActorFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const AuthorList = props => (
  <List
    {...props}
    filters={<VoiceActorFilter />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
)

export default AuthorList
