import React from 'react'

const CustomSvgField = props => {
  const { record } = props
  if (record && record.rawFile && record.rawFile instanceof File) {
    return (
      <img
        src={URL.createObjectURL(record.rawFile)}
        style={{ height: 80, width: 80 }}></img>
    )
  }
  return <embed src={record} style={{ height: 80, width: 80 }}></embed>
}

export default CustomSvgField
