import React from 'react'
import { DeleteButton } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import ActionButton from './ActionButton'
import { ROLES } from '../constants'

const ApproveButtons = ({
  record,
  permissions,
  service,
  statusField = 'status',
}) => {
  const approveBtns = () => (
    <Grid container>
      <Grid container xs={6} direction="column">
        <ActionButton
          label="Approve"
          action="approve"
          color="primary"
          service={service}
          record={record}
          permissions={permissions}
          statusField={statusField}
        />
        <ActionButton
          label="Reject"
          action="reject"
          color="default"
          service={service}
          record={record}
          permissions={permissions}
          statusField={statusField}
        />
        <DeleteButton record={record} />
      </Grid>
    </Grid>
  )

  if (
    !record ||
    ![
      ROLES.ADMIN,
      ROLES.MARKETING_MANAGER,
      ROLES.STATISTICAL_STAFF,
      ROLES.HEAD_OF_CUSTOMER_SERVICE,
    ].includes(permissions) ||
    ['approved', 'rejected'].includes(record[statusField])
  )
    return null

  switch (permissions) {
    case ROLES.ADMIN:
    case ROLES.MARKETING_MANAGER:
    case ROLES.STATISTICAL_STAFF:
    case ROLES.HEAD_OF_CUSTOMER_SERVICE: {
      if (!record.approveRole) return approveBtns()
      return null
    }
    default:
      return null
  }
}

export default ApproveButtons
