import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin'

const CustomEntityField = ({ record }) => {
  if (record && record.type === 'book') {
    return (
      <ReferenceField label="Book" source="entityId" reference="books">
        <TextField source="title" />
      </ReferenceField>
    )
  }
  if (record && record.type === 'course') {
    return (
      <ReferenceField label="Course" source="entityId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
    )
  }
  return '-'
}

const ChallengeRewardRedeemList = props => (
  <List {...props} perPage={1000} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="type" />
      <CustomEntityField label="Book/Course" />
      <ReferenceField
        label="Challenge Name"
        source="challengeRewardId"
        reference="challenge-rewards">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)

export default ChallengeRewardRedeemList
