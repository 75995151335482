import React from 'react'
import { Edit } from 'react-admin'

import GuidedMeditationForm from './GuidedMeditationForm'

const GuidedMeditationEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <GuidedMeditationForm permissions={props.permissions} />
    </Edit>
  )
}

export default GuidedMeditationEdit
