import React from 'react'
import { SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'
import GridContainer from '../../components/GridContainer'

import { validateRequired } from '../../utils/validate'

const styles = {
  container: {
    width: 'unset',
  },
}

const ReferralBookForm = props => {
  return (
    <SimpleForm {...props} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput
            label="Book title"
            source="bookId"
            reference="books"
            filterToQuery={searchText => ({
              title: { $iLike: `%${searchText}%` },
            })}
            validate={validateRequired('Book title')}>
            <AutocompleteInput
              optionText="title"
              options={{
                fullWidth: true,
              }}
              required
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(ReferralBookForm)
