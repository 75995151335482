import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'
import moment from 'moment'
import UserDetailField from '../../components/UserDetailField'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import ApproveActionButtons from '../../components/ApproveAction/ApproveButtons'
import { exportExcel } from '../../utils/exportExcel'
import FilterComponent from '../../components/Filter'
import { ROLES } from '../../components/constants'

const CsUserSubscriptionFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Approved By"
      source="createdBy"
      reference="users"
      filter={{
        role: [ROLES.ADMIN, ROLES.MARKETING_MANAGER, ROLES.STATISTICAL_STAFF],
      }}
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>

    <SelectInput
      label="Filter"
      source="date"
      choices={[
        { id: 'all', name: 'All' },
        { id: 'this_month', name: 'This month' },
        { id: 'last_month', name: 'Last month' },
        { id: 'custom', name: 'Choose date' },
      ]}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const exporter = records => {
  const data = records.map(record => {
    return {
      type: record.type,
      Status: record.status,
      Reason: record.reason,
      'Created At': moment(record.createdAt).format('MM/DD/YYYY'),
      'Created By': record.createdUser.name,
      'Approved At': moment(record.updatedAt).format('MM/DD/YYYY'),
      'Approved By': record.updatedUser.name,
    }
  })
  exportExcel({
    data,
    title: `cs_user_books_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const CsUserSubscriptionList = props => {
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        exporter={exporter}
        filters={<CsUserSubscriptionFilter />}>
        <Datagrid>
          <UserDetailField label="User" />
          <TextField source="type" />
          <TextField source="reason" />
          <ReferenceField
            label="Created by"
            source="createdBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Updated by"
            source="updatedBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ApproveStatus label="Status" />
          <ApproveActionButtons
            permissions={props.permissions}
            service="cs-user-subscriptions"
          />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CsUserSubscriptionList
