import React from 'react'
import { Create } from 'react-admin'

import MusicForm from './MusicForm'

const MusicCreate = props => (
  <Create {...props}>
    <MusicForm permissions={props.permissions} />
  </Create>
)

export default MusicCreate
