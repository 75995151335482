import React, { useMemo } from 'react'

import {
  TextInput,
  NumberInput,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { useField, useForm } from 'react-final-form'
import {
  CONFIGURATION_SETTING_DATA_TYPE,
  CONFIGURATION_SETTING_DATA_TYPE_LABEL,
  CONFIGURATION_SETTING_USE_SCOPE,
  CONFIGURATION_SETTING_USE_SCOPE_LABEL,
} from './constants'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const useScopeOptions = [
  {
    id: CONFIGURATION_SETTING_USE_SCOPE.HOMEPAGE_CONFIGURATIONS,
    name:
      CONFIGURATION_SETTING_USE_SCOPE_LABEL[
        CONFIGURATION_SETTING_USE_SCOPE.HOMEPAGE_CONFIGURATIONS
      ],
  },
  {
    id: CONFIGURATION_SETTING_USE_SCOPE.PODCAST_TAB_CONFIGURATIONS,
    name:
      CONFIGURATION_SETTING_USE_SCOPE_LABEL[
        CONFIGURATION_SETTING_USE_SCOPE.PODCAST_TAB_CONFIGURATIONS
      ],
  },
  {
    id: CONFIGURATION_SETTING_USE_SCOPE.ENGLISH_BOOK_TAB_CONFIGURATIONS,
    name:
      CONFIGURATION_SETTING_USE_SCOPE_LABEL[
        CONFIGURATION_SETTING_USE_SCOPE.ENGLISH_BOOK_TAB_CONFIGURATIONS
      ],
  },
  {
    id: CONFIGURATION_SETTING_USE_SCOPE.COURSE_TAB_CONFIGURATIONS,
    name:
      CONFIGURATION_SETTING_USE_SCOPE_LABEL[
        CONFIGURATION_SETTING_USE_SCOPE.COURSE_TAB_CONFIGURATIONS
      ],
  },
]

const dataTypeOptions = [
  {
    id: CONFIGURATION_SETTING_DATA_TYPE.NUMBER,
    name:
      CONFIGURATION_SETTING_DATA_TYPE_LABEL[
        CONFIGURATION_SETTING_DATA_TYPE.NUMBER
      ],
  },
  {
    id: CONFIGURATION_SETTING_DATA_TYPE.STRING,
    name:
      CONFIGURATION_SETTING_DATA_TYPE_LABEL[
        CONFIGURATION_SETTING_DATA_TYPE.STRING
      ],
  },
  {
    id: CONFIGURATION_SETTING_DATA_TYPE.BOOLEAN,
    name:
      CONFIGURATION_SETTING_DATA_TYPE_LABEL[
        CONFIGURATION_SETTING_DATA_TYPE.BOOLEAN
      ],
  },
]

const CustomArrayInput = ({ source, dataType, ...rest }) => {
  const InputComponent = useMemo(() => {
    if (dataType === CONFIGURATION_SETTING_DATA_TYPE.NUMBER) {
      return NumberInput
    } else if (dataType === CONFIGURATION_SETTING_DATA_TYPE.BOOLEAN) {
      return BooleanInput
    } else {
      return TextInput
    }
  }, [dataType])
  return (
    <ArrayInput source={source} fullWidth variant="standard" {...rest}>
      <SimpleFormIterator inline>
        <InputComponent fullWidth variant="standard" />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const SettingsInput = ({ source, ...rest }) => {
  const form = useForm()

  const dataTypeField = useField('dataType', form.getState())
  const dataType = dataTypeField.input.value

  const multipleField = useField('multiple', form.getState())
  const isMultiple = multipleField.input.value

  if (!dataType) {
    return null
  }

  if (isMultiple) {
    return <CustomArrayInput source={source} {...rest} dataType={dataType} />
  }

  if (dataType === CONFIGURATION_SETTING_DATA_TYPE.NUMBER) {
    return <NumberInput {...rest} source={source} />
  } else if (dataType === CONFIGURATION_SETTING_DATA_TYPE.BOOLEAN) {
    return <BooleanInput {...rest} source={source} />
  } else {
    return <TextInput {...rest} source={source} />
  }
}

const CommissionForm = ({ ...props }) => {
  const { isCreating } = props
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        {/* Use Scope */}
        <Grid container item xs={12}>
          <SelectInput
            label="Use Scope"
            source="useScope"
            choices={useScopeOptions}
            alwaysOn
            fullWidth
            disabled={!isCreating}
            allowEmpty={false}
          />
        </Grid>

        {/* Id */}

        <Grid container item xs={12}>
          <TextInput
            disabled={!isCreating}
            fullWidth
            source="id"
            variant="standard"
          />
        </Grid>

        {/* Description */}

        <Grid container item xs={12}>
          <TextInput
            label="Description"
            source="description"
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* Data Type */}

        <Grid container item xs={12}>
          <SelectInput
            label="Data Type"
            source="dataType"
            choices={dataTypeOptions}
            alwaysOn
            fullWidth
            disabled={!isCreating}
            allowEmpty={false}
          />
        </Grid>

        {/* Is multiple value */}

        <Grid container item xs={12}>
          <BooleanInput
            label="Multiple Value"
            source="multiple"
            alwaysOn
            fullWidth
            disabled={!isCreating}
            allowEmpty={false}
            defaultValue={false}
          />
        </Grid>

        {/* Settings */}
        <Grid container item xs={12}>
          <SettingsInput
            label="Settings"
            source="settings"
            fullWidth
            variant="standard"
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(CommissionForm)
