import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  email,
  minValue,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  maxValue,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const statuses = [{ id: true, name: 'True' }, { id: false, name: 'False' }]

const AffiliateCollectionEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <TextInput disabled fullWidth source="webLink"></TextInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput disabled fullWidth source="deepLink"></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="Name"
              source="name"
              fullWidth
              validate={validateRequired('Name')}
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Status"
              source="status"
              choices={statuses}
              fullWidth
              required
              variant="standard"
              defaultValue={true}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              fullWidth
              variant="standard"
              validate={email()}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Slug"
              source="slug"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Start date"
              source="startAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Expire date"
              source="expireAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <NumberInput
              label="Commission"
              source="commission"
              fullWidth
              validate={(minValue(0), maxValue(100))}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput
              variant="standard"
              label="Collection"
              source="collectionId"
              reference="collection-codes"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}>
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true,
                }}
              />
            </ReferenceInput>
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Edit>
  )
}

export default AffiliateCollectionEdit
