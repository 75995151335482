import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  TextInput,
} from 'react-admin'

import DeleteButton from '../../components/DeleteButton'
import CustomSwitchField from '../../components/CustomSwitchField'
import { ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const Filter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </FilterComponent>
)

const MeditationBackgroundList = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<Filter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CustomSwitchField
        resource="meditation-backgrounds"
        label="Status"
        switchField="status"
        disabled={
          ![ROLES.ADMIN, ROLES.CPSO, ROLES.CONTENT_EDITOR].includes(permissions)
        }
      />
      <EditButton />
      <DeleteButton permissions={permissions} />
    </Datagrid>
  </List>
)

export default MeditationBackgroundList
