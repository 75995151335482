import React, { Component } from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import { addField } from 'ra-core'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import 'react-mde/lib/styles/css/react-mde-all.css'
import './styles.scss'

const styles = {}

class MarkdownInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      tab: 'write',
    }
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props

    this.setState({ value })

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
    })
  }

  handleValueChange(value) {
    this.setState({ value })
    this.props.input.onChange(value)
  }

  handleTabChange(tab) {
    this.setState({ tab })
  }

  render() {
    const { error, touched } = this.props.meta
    return (
      <FormControl fullWidth={true} className="ra-input-mde">
        <span
          style={{
            color: touched && error ? '#f44336' : 'rgba(0, 0, 0, 0.54)',
            marginBottom: 6,
            fontSize: 12,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
          }}>
          {this.props.label} *
        </span>
        <ReactMde
          onChange={value => this.handleValueChange(value)}
          onTabChange={tab => this.handleTabChange(tab)}
          value={this.state.value}
          generateMarkdownPreview={markdown =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
          selectedTab={this.state.tab}
        />
        {touched && error && (
          <p
            style={{
              margin: 0,
              fontSize: '0.75rem',
              textAlign: 'left',
              marginTop: 8,
              minHeight: '1em',
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
              color: '#f44336',
            }}>
            {error.message}
          </p>
        )}
      </FormControl>
    )
  }
}

const MarkDownInputWithField = addField(withStyles(styles)(MarkdownInput))

MarkDownInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true,
}

export default MarkDownInputWithField
