import React from 'react'
import { Create } from 'react-admin'

import CommissionForm from './CommissionForm'

const CommissionCreate = props => (
  <Create {...props}>
    <CommissionForm isCreating permissions={props.permissions} />
  </Create>
)

export default CommissionCreate
