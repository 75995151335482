import React from 'react'
import { Edit } from 'react-admin'

import FreeAccountsForm from './FreeAccountsForm'

const FreeAccountsEdit = props => (
  <Edit undoable={false} {...props}>
    <FreeAccountsForm />
  </Edit>
)

export default FreeAccountsEdit
