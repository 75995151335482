import React from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
const bannerStatusField = props => {
  const { record, dataProvider } = props

  const handleChange = async () => {
    const data = {
      status: record.status === true ? false : true,
    }

    dataProvider(
      UPDATE,
      'banners',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Banner updated', level: 'info' },
          redirectTo: '/banners',
        },
        onFailure: {
          notification: {
            body: 'Error: banners not updated',
            level: 'warning',
          },
        },
      },
    )
  }

  return (
    <FormControlLabel
      control={<Switch checked={record.status} onChange={handleChange} />}
    />
  )
}

export default withDataProvider(bannerStatusField)
