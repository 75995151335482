import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  TextInput,
} from 'react-admin'
import FilterComponent from '../../components/Filter'
import IsFreeField from '../../components/IsFreeField'
import DeleteButton from '../../components/DeleteButton'
import CustomSwitchField from '../../components/CustomSwitchField'
import { ROLES } from '../../components/constants'

const GuidedMeditationFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </FilterComponent>
)

const GuidedMeditationList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<GuidedMeditationFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source="title" />
        <IsFreeField resource="guided-meditations" label="Is Free" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <CustomSwitchField
          resource="guided-meditations"
          label="Publish"
          switchField="status"
          disabled={
            ![ROLES.ADMIN, ROLES.CPSO, ROLES.CONTENT_EDITOR].includes(
              permissions,
            )
          }
        />
        <EditButton />
        <DeleteButton permissions={props.permissions} />
      </Datagrid>
    </List>
  )
}

export default GuidedMeditationList
