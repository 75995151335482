import React from 'react'

import {
  TextInput,
  SimpleForm,
  SelectInput,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import GridContainer from '../../components/GridContainer'
import CustomImageField from '../../components/CustomImageField'

import BookToolbar from '../../components/BookToolbar'
import { validateRequired, validateUnique } from '../../utils/validate'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const CollectionForm = props => {
  const TYPES = [
    { id: 'book', name: 'Book', disabled: props.editing },
    { id: 'book_summary', name: 'Book summary', disabled: props.editing },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        {props.editing && (
          <Grid item xs={12}>
            <TextInput source="slug" fullWidth variant="standard" disabled />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type')]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="type"
            choices={TYPES}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Type')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            const type = formData.type

            switch (type) {
              case 'book':
                return (
                  <ArrayInput
                    label="Books"
                    source="books"
                    fullWidth
                    validate={[
                      validateRequired('Books'),
                      validateUnique('bookId', true),
                    ]}>
                    <SimpleFormIterator>
                      <ReferenceInput
                        label="Book"
                        source="bookId"
                        reference="books"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                        validate={validateRequired('Book')}>
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                        />
                      </ReferenceInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              case 'book_summary':
                return (
                  <ArrayInput
                    label="Book Summaries"
                    source="bookSummaries"
                    fullWidth
                    validate={[
                      validateRequired('Book Summaries'),
                      validateUnique('bookSummaryId', true),
                    ]}>
                    <SimpleFormIterator>
                      <ReferenceInput
                        label="Book Summary"
                        source="bookSummaryId"
                        reference="book-summaries"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                        validate={validateRequired('Book Summary')}>
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                        />
                      </ReferenceInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              default:
                return null
            }
          }}
        </FormDataConsumer>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(CollectionForm)
