/* eslint-disable react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'

import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  FunctionField,
  GET_ONE,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  withDataProvider,
} from 'react-admin'
import Button from '@material-ui/core/Button'

import { formatPrice } from '../../utils/formatCurrency'
import { FILTER_MONTH, FILTER_YEAR, ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'
import axios from 'axios'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { formatMonthWithZero } from '../../utils/parseDate'
import { tryMergeRows } from '../../utils/exportExcel'
import getDataProvider from '../../dataProvider'
import _ from 'lodash'

const Actions = ({ resource, currentSort, filterValues, exporter }) => {
  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={data => {
        exporter(data, filterValues)
      }}
      maxResults={10000}
    />
  )
}

const exporter = async (records, filterValues) => {
  const monthlyDetail = await getDataProvider()(
    GET_ONE,
    'publisher-dashboard-monthly',
    {
      id: null,
      publisherId: filterValues && filterValues.publisherId,
      fromMonth: filterValues && filterValues.fromMonth,
      fromYear: filterValues && filterValues.fromYear,
      toMonth: filterValues && filterValues.toMonth,
      toYear: filterValues && filterValues.toYear,
      $limit: 10000,
    },
  )
    .then(({ data }) => {
      return data.data
    })
    .catch(console.log)

  axios({
    url: '/publisher_commission_report.xlsx',
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    const excelBlob = response.data

    const workbook = new ExcelJS.Workbook()
    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer = reader.result

      workbook.xlsx.load(arrayBuffer).then(async workbook => {
        const templateSheet = workbook.getWorksheet(1)
        const worksheets = [templateSheet]

        // Clone sheet template
        //for (const chapterKey in chapterFiles) {
        let monthlyDetailItemIndex
        for (monthlyDetailItemIndex in monthlyDetail) {
          const monthlyDetailItem = monthlyDetail[monthlyDetailItemIndex]
          let monthlySheet = workbook.addWorksheet(
            `${formatMonthWithZero(monthlyDetailItem.month)}.${
              monthlyDetailItem.year
            }`,
          )
          let tmpModel = Object.assign(templateSheet.model, {
            mergeCells: templateSheet.model.merges,
          })
          tmpModel.name = `${formatMonthWithZero(monthlyDetailItem.month)}.${
            monthlyDetailItem.year
          }`
          monthlySheet.model = tmpModel
          worksheets.push(monthlySheet)
        }

        const { fromMonth, fromYear, toMonth, toYear } = filterValues || {}
        const formattedFromMonth = formatMonthWithZero(fromMonth)
        const formattedToMonth = formatMonthWithZero(toMonth)

        let worksheetIndex
        for (worksheetIndex in worksheets) {
          const worksheet = worksheets[worksheetIndex]
          if (Number(worksheetIndex) === 0) {
            createWorksheetData(
              worksheet,
              records,
              `${formattedFromMonth}.${fromYear}-${formattedToMonth}.${toYear}`,
              null,
              null,
            )
          } else {
            const monthData = monthlyDetail[worksheetIndex - 1]
            createWorksheetData(
              worksheet,
              monthData.data,
              null,
              monthData.month,
              monthData.year,
            )
          }
        }

        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        saveAs(
          blob,
          `commission_report_${formattedFromMonth}_${fromYear}_${formattedToMonth}_${toYear}.xlsx`,
        )
      })
    }

    reader.readAsArrayBuffer(excelBlob)
  })

  const createWorksheetData = (worksheet, records, sheetName, month, year) => {
    // Delete example rows
    worksheet.spliceRows(14, 1)
    const values = records.map((record, index) => {
      const rowIndex = index + 13
      return [
        null,
        index + 1,
        record.bookId,
        record.title,
        moment(record.datePublished).toDate(),
        (record.publisher && record.publisher.name) || '',
        record.saleQuantity,
        record.revenue,
        record.deduction,
        {
          formula: `H${rowIndex}-I${rowIndex}`,
        },
        record.commission / 100,
        {
          formula: `J${rowIndex}*K${rowIndex}`,
        },
      ]
    })

    const detailRows = worksheet.insertRows(14, values)
    const sourceRow = worksheet.getRow(13)
    detailRows.forEach(row => {
      row.height = 20
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const sourceCell = sourceRow.getCell(colNumber)
        cell.style = Object.assign({}, sourceCell.style)
        cell.numFmt = sourceCell.numFmt
        cell.font = Object.assign({}, sourceCell.font)
        cell.alignment = Object.assign({}, sourceCell.alignment)
        cell.border = Object.assign({}, sourceCell.border)
        cell.fill = Object.assign({}, sourceCell.fill)
        cell.protection = Object.assign({}, sourceCell.protection)
        const cellTextLength = cell.value ? cell.value.toString().length : 0
        const estimatedRowHeight = Math.ceil(cellTextLength / 40) * 20
        if (estimatedRowHeight > row.height) {
          row.height = estimatedRowHeight
        }
      })
    })

    worksheet.spliceRows(13, 1)

    const summaryIndex = 14 + records.length
    const lastDetailRowIndex = 12 + records.length
    const summaryRow = worksheet.getRow(summaryIndex)
    summaryRow.getCell('H').value = {
      formula: `SUM(H13:H${lastDetailRowIndex})`,
    }
    summaryRow.getCell('I').value = {
      formula: `SUM(I13:I${lastDetailRowIndex})`,
    }
    summaryRow.getCell('J').value = {
      formula: `SUM(J13:J${lastDetailRowIndex})`,
    }
    summaryRow.getCell('L').value = {
      formula: `SUM(L13:L${lastDetailRowIndex})`,
    }

    tryMergeRows(worksheet, `B${summaryIndex}:G${summaryIndex}`)
    // Note
    tryMergeRows(worksheet, `D${summaryIndex + 2}:L${summaryIndex + 2}`)
    tryMergeRows(worksheet, `D${summaryIndex + 3}:L${summaryIndex + 3}`)
    tryMergeRows(worksheet, `D${summaryIndex + 4}:L${summaryIndex + 4}`)
    tryMergeRows(worksheet, `D${summaryIndex + 5}:L${summaryIndex + 5}`)

    const { fromMonth, fromYear, toMonth, toYear } = filterValues || {}
    const formattedFromMonth = formatMonthWithZero(fromMonth)
    const formattedToMonth = formatMonthWithZero(toMonth)
    // sheet
    if (sheetName) {
      worksheet.name = sheetName
    }

    // publisher name
    const publishersName = _.uniq(
      records.map(r => r.publisher && r.publisher.name),
    ).filter(Boolean)

    const publisherRow = worksheet.getRow(8)
    publisherRow.getCell('D').value =
      publishersName.length === 1 ? publishersName[0] : ''

    // date time
    const dateTimeRow = worksheet.getRow(9)
    if (month && year) {
      dateTimeRow.getCell('D').value = `Tháng ${formatMonthWithZero(
        month,
      )} Năm ${year} / Month ${formatMonthWithZero(month)} Year ${year}`
    } else {
      dateTimeRow.getCell(
        'D',
      ).value = `Từ Tháng ${formattedFromMonth} Năm ${fromYear} Đến Tháng ${formattedToMonth} Năm ${toYear} / From Month ${formattedFromMonth} Year ${fromYear} To Month ${formattedToMonth} Year ${toYear}`
    }

    function scaleWidth(width) {
      // To fix with the real width of excel file
      return width * 0.89
    }

    worksheet.getColumn('A').width = scaleWidth(1.8)
    worksheet.getColumn('B').width = scaleWidth(5.2)
    worksheet.getColumn('C').width = scaleWidth(7.2)
    worksheet.getColumn('D').width = scaleWidth(47.2)
    worksheet.getColumn('E').width = scaleWidth(12.4)
    worksheet.getColumn('F').width = scaleWidth(13)
    worksheet.getColumn('G').width = scaleWidth(7.8)
    worksheet.getColumn('H').width = scaleWidth(15.8)
    worksheet.getColumn('I').width = scaleWidth(15.8)
    worksheet.getColumn('J').width = scaleWidth(15.8)
    worksheet.getColumn('K').width = scaleWidth(13.8)
    worksheet.getColumn('L').width = scaleWidth(19.4)
  }
}

const PROPS = {
  TOTAL_REVENUE: 'totalRevenue',
  TOTAL_DEDUCTION: 'totalDeduction',
  TOTAL_SHARED_REVENUE: 'totalSharedRevenue',
  TOTAL_GROSS_COMMISSION: 'totalGrossCommission',
}

const CustomDatagrid = withDataProvider(props => {
  const [state, setState] = useState({
    [PROPS.TOTAL_REVENUE]: 0,
    [PROPS.TOTAL_DEDUCTION]: 0,
    [PROPS.TOTAL_SHARED_REVENUE]: 0,
    [PROPS.TOTAL_GROSS_COMMISSION]: 0,
  })
  const [isShowing = {}, setIsShowing] = useState({
    showFD: false,
    showTD: false,
  })

  const [isShowRevenue, toggleShowRevenue] = useState(false)

  const updateState = useCallback(
    data => setState(prev => ({ ...prev, ...data })),
    [],
  )

  const handleSetRevenue = () => {
    getDataProvider()(GET_ONE, 'publisher-dashboard-revenue-admin', {
      id: null,
      publisherId: props.filterValues && props.filterValues.publisherId,
      fromMonth: props.filterValues && props.filterValues.fromMonth,
      fromYear: props.filterValues && props.filterValues.fromYear,
      toMonth: props.filterValues && props.filterValues.toMonth,
      toYear: props.filterValues && props.filterValues.toYear,
    })
      .then(({ data }) => {
        const {
          totalRevenue,
          totalDeduction,
          totalSharedRevenue,
          totalGrossCommission,
        } = data
        updateState({
          [PROPS.TOTAL_REVENUE]: totalRevenue,
          [PROPS.TOTAL_DEDUCTION]: totalDeduction,
          [PROPS.TOTAL_SHARED_REVENUE]: totalSharedRevenue,
          [PROPS.TOTAL_GROSS_COMMISSION]: totalGrossCommission,
        })
      })
      .catch(console.log)
  }

  useEffect(() => {
    if (isShowing.showFD)
      setTimeout(() => setIsShowing({ ...isShowing, showFD: false }), 5000)
    if (isShowing.showTD)
      setTimeout(() => setIsShowing({ ...isShowing, showTD: false }), 5000)
  }, [isShowing])

  useEffect(() => {
    const debounced = debounce(() => {
      handleSetRevenue()
    }, 1000)
    debounced.call()
  }, [props.filterValues])

  return (
    <Fragment>
      <div style={{ marginLeft: 12 }}>
        <div
          style={{
            color: '#697a99',
            fontSize: 14,
            marginTop: 10,
            marginBottom: 10,
          }}>
          Phiên bản mới của Fonos Admin được cập nhật từ tháng 10/2023.
          <br />
          Báo cáo doanh số hoa hồng từ tháng 9/2023 trở về trước xin Quý Nhà
          xuất bản lấy theo số liệu đã chốt với kế toán trước đó.
        </div>
        {isShowRevenue ? (
          <>
            <h3>Total revenue: {formatPrice(state[PROPS.TOTAL_REVENUE])}</h3>
            <h3>
              Total deduction: {formatPrice(state[PROPS.TOTAL_DEDUCTION])}
            </h3>
            <h3>
              Total shared revenue:{' '}
              {formatPrice(state[PROPS.TOTAL_SHARED_REVENUE])}
            </h3>
            <h3>
              Total gross commission:{' '}
              {formatPrice(state[PROPS.TOTAL_GROSS_COMMISSION])}
            </h3>
            <Button color="primary" onClick={() => toggleShowRevenue(false)}>
              Hide revenue
            </Button>
          </>
        ) : (
          <Button color="primary" onClick={() => toggleShowRevenue(true)}>
            Show revenue
          </Button>
        )}
      </div>
      <Datagrid {...props}>
        <TextField source="bookId" label={'Book Id'} sortable={false} />
        <TextField source="title" label={'Book Title'} sortable={false} />
        <DateField
          source="datePublished"
          label="Publish Date"
          sortable={false}
        />
        <TextField source="publisher.name" label="Publisher" sortable={false} />
        <TextField source="saleQuantity" label="Qty" sortable={false} />
        <FunctionField
          source="revenue"
          label="Total revenue"
          sortable={false}
          render={record => (
            <div style={{ textAlign: 'right' }}>
              {formatPrice(record.revenue)}
            </div>
          )}
        />
        <FunctionField
          source="deduction"
          sortable={false}
          label={'Deductions'}
          render={record => (
            <div style={{ textAlign: 'right' }}>
              {formatPrice(record.deduction)}
            </div>
          )}
        />
        <FunctionField
          source="sharedRevenue"
          sortable={false}
          label={'Shared revenue'}
          render={record => (
            <div style={{ textAlign: 'right' }}>
              {formatPrice(record.sharedRevenue)}
            </div>
          )}
        />
        <NumberField
          source="commission"
          label="% Commission"
          style={{ textAlign: 'right' }}
          sortable={false}
        />
        <FunctionField
          source="grossCommission"
          sortable={false}
          label={'Gross commission'}
          style={{ textAlign: 'right' }}
          render={record => (
            <div style={{ textAlign: 'right' }}>
              {formatPrice(record.grossCommission)}
            </div>
          )}
        />
      </Datagrid>
    </Fragment>
  )
})

const CustomFilter = props => {
  const monthOptions = useMemo(() => {
    const { year } = props.filterValues || {}
    if (year <= 2023) {
      return [
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
      ]
    }
    return FILTER_MONTH
  }, [props.filterValues])

  const yearOptions = useMemo(() => {
    const { month } = props.filterValues || {}
    if (month < 10) {
      return [{ id: 2024, name: '2024' }]
    }
    return FILTER_YEAR
  }, [props.filterValues])

  return (
    <FilterComponent
      {...props}
      variant="standard"
      styles={{ marginLeft: '12px' }}>
      <ReferenceInput
        label={'Publisher'}
        source={'publisherId'}
        perPage={1000}
        reference="users"
        filterToQuery={searchText => ({
          name: { $iLike: `%${searchText}%` },
          role: ROLES.PUBLISHER,
        })}
        alwaysOn
        allowEmpty>
        <AutocompleteInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <SelectInput
        label="From Month"
        source="fromMonth"
        choices={monthOptions}
        alwaysOn
        allowEmpty={false}></SelectInput>
      <SelectInput
        label="From Year"
        source="fromYear"
        choices={yearOptions}
        alwaysOn
        allowEmpty={false}></SelectInput>
      <SelectInput
        label="To Month"
        source="toMonth"
        choices={monthOptions}
        alwaysOn
        allowEmpty={false}></SelectInput>
      <SelectInput
        label="To Year"
        source="toYear"
        choices={yearOptions}
        alwaysOn
        allowEmpty={false}></SelectInput>
    </FilterComponent>
  )
}

const DashboardAdmin = props => {
  const mapQueryToProps = (initProps, search) => {
    let queryParams = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/\?/g, '')
          .replace(/%3A/g, ':')
          .replace(/%2C/g, ',')
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    )
    let perPage = queryParams.perPage
    let page = queryParams.page
    let filter = JSON.parse(queryParams.filter)
    const newProps = { ...initProps }
    newProps.perPage = +perPage
    newProps.page = +page
    newProps.location = props.location
    newProps.filter = filter
    newProps.resource = 'publisher-dashboard-admin'
    return newProps
  }

  const [initProps, setInitProps] = useState(() => {
    const baseProps = {
      basePath: '/',
      hasCreate: false,
      hasEdit: false,
      hasList: true,
      hasShow: false,
      location: { pathname: '/', search: '', hash: '', state: undefined },
      match: { path: '/', url: '/', isExact: true, params: {} },
      options: {},
      permissions: null,
      resource: 'publisher-dashboard-admin',
      perPage: 1000,
      page: 0,
      exporter,
      syncWithLocation: false,
    }
    const { search } = props.location
    if (search) {
      return mapQueryToProps(baseProps, search)
    }

    return baseProps
  })

  useEffect(() => {
    const { search } = props.location
    if (search) {
      const newProps = mapQueryToProps(initProps, search)
      setInitProps(newProps)
    }
  }, [props.location])

  const lastMonth = moment().subtract(1, 'month')

  return (
    <List
      {...initProps}
      title={`Publisher dashboard`}
      bulkActionButtons={false}
      filters={<CustomFilter />}
      actions={<Actions />}
      filterDefaultValues={{
        fromMonth: lastMonth.month() + 1,
        fromYear: lastMonth.year(),
        toMonth: lastMonth.month() + 1,
        toYear: lastMonth.year(),
      }}>
      <CustomDatagrid />
    </List>
  )
}

export default DashboardAdmin
