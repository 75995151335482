import React from 'react'

const DateTimeField = ({ record, source }) => {
  const dateTime = record[source] // Assuming your API returns a DateTime field
  const formattedDateTime = dateTime ? new Date(dateTime).toLocaleString() : ''

  return <span>{formattedDateTime}</span>
}

export default DateTimeField
