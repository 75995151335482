import React from 'react'
import { Create } from 'react-admin'

import CategoryForm from './CategoryForm'

const CategoryCreate = props => (
  <Create {...props}>
    <CategoryForm />
  </Create>
)

export default CategoryCreate
