import React from 'react'
import { authClient } from './provider'
import { Admin, Resource } from 'react-admin'

import feathersClient from './feathersClient'

import customRoutes from './customRoutes'
import getDataProvider from './dataProvider'
import MyLayout from './MyUserMenu'
import LoginPage from './components/LoginPage'
import Dashboard from './components/Dashboard/index'
import resources from './resources'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ROLE_DETAIL_KEY } from './provider/authClient'
import { hyphenToCamelCaseWithPrefix } from './utils/case'
import withAwsWaf from './withAwsWaf'

const authClientOptions = {
  storageKey: 'feathers-jwt',
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login',
  permissionsField: 'role',
}

const theme = createTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  },
})

const App = () => {
  feathersClient.get().reAuthenticate()

  return (
    <MuiThemeProvider theme={theme}>
      <Admin
        title="Fonos"
        loginPage={LoginPage}
        layout={MyLayout}
        customRoutes={customRoutes}
        authProvider={authClient(feathersClient.get(), authClientOptions)}
        dashboard={Dashboard}
        dataProvider={getDataProvider()}>
        {() => {
          const roleDetail = JSON.parse(
            localStorage.getItem(ROLE_DETAIL_KEY) || '{}',
          )
          const can =
            (roleDetail &&
              roleDetail.permission &&
              roleDetail.permission.can) ||
            {}

          const readResources = (can.read || []).map(r =>
            hyphenToCamelCaseWithPrefix(r, 'read'),
          )

          const manageResources = can.manage || []

          const routes = [...readResources, ...manageResources]
            .map(route => resources[route])
            .filter(Boolean)
          if (routes.length) {
            return routes
          }
          return [<Resource key={1} name="users" />]
        }}
      </Admin>
    </MuiThemeProvider>
  )
}

export default withAwsWaf(App)
