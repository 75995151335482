import React from 'react'
import { Edit } from 'react-admin'
import VoiceActorForm from './VoiceActorForm'

const VoiceActorEdit = props => (
  <Edit undoable={false} {...props}>
    <VoiceActorForm />
  </Edit>
)

export default VoiceActorEdit
