import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  SimpleForm,
  TextInput,
  minValue,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'
import { useField, useForm } from 'react-final-form'

export const TYPE_CREDITS = [
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'annually',
    name: 'Annually',
  },
  {
    id: 'bundle_3_v2',
    name: 'Bundle 3-credit',
  },
  {
    id: 'bundle_5_v2',
    name: 'Bundle 5-credit',
  },
  {
    id: 'bundle_10_v2',
    name: 'Bundle 10-credit',
  },
  {
    id: 'free',
    name: 'Free',
  },
]

const PresetInput = props => {
  const form = useForm()
  const field = useField('preset', form.getState())
  useEffect(() => {
    if (!field.input.value) return
    switch (field.input.value) {
      case 'bundle_3_v2':
        form.batch(() => {
          form.change('type', 'bundle_3_v2')
          form.change('credits', 3)
        })
        break
      case 'bundle_5_v2':
        form.batch(() => {
          form.change('type', 'bundle_5_v2')
          form.change('credits', 5)
        })
        break
      case 'bundle_10_v2':
        form.batch(() => {
          form.change('type', 'bundle_10_v2')
          form.change('credits', 10)
        })
        break
      default:
        form.batch(() => {
          form.change('type', undefined)
          form.change('credits', undefined)
        })
        break
    }
  }, [field.input.value])

  return <SelectInput {...props}></SelectInput>
}

const UserCreditsForm = props => {
  const [
    shouldDisableCreditSelector,
    setShouldDisableCreditSelector,
  ] = useState(false)

  const onPresetInputChange = useCallback(e => {
    setShouldDisableCreditSelector(e.target.value && e.target.value !== 'other')
  }, [])

  return (
    <SimpleForm {...props} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput
            label="Username"
            source="userId"
            reference="users"
            filterToQuery={searchText => ({
              username: { $iLike: `%${searchText}%` },
            })}
            validate={validateRequired('Username')}>
            <AutocompleteInput
              optionText="username"
              variant="standard"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <PresetInput
            onChange={onPresetInputChange}
            label="Presets"
            source="preset"
            choices={[
              { id: 'bundle_3_v2', name: 'Bundle 3-credit' },
              { id: 'bundle_5_v2', name: 'Bundle 5-credit' },
              { id: 'bundle_10_v2', name: 'Bundle 10-credit' },
              { id: 'other', name: 'Other' },
            ]}
            fullWidth
            alwaysOn
            allowEmpty={false}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="credits"
            required
            fullWidth
            variant="standard"
            disabled={shouldDisableCreditSelector}
            validate={[validateRequired('Credits'), minValue(1)]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Type credit"
            source="type"
            choices={TYPE_CREDITS}
            alwaysOn
            fullWidth
            required
            disabled={shouldDisableCreditSelector}
            validate={validateRequired('Type')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="reason"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Reason')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

const mapStateToProps = state => {
  return {
    users: state.admin.resources.users.data,
  }
}

export default connect(
  mapStateToProps,
  null,
)(UserCreditsForm)
