import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import UserDetailField from '../../components/UserDetailField'
import moment from 'moment/moment'
import { exportExcel } from '../../utils/exportExcel'

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      Code: record.codeString,
      'Code Id': record.codeId,
      'Recipient email':
        record.code && (record.code.recipientEmail || record.code.invoiceEmail),
      'Phone number':
        record.code &&
        record.code.payment_order &&
        record.code.payment_order.phone,
      'User email': record.user && record.user.email,
      'User phone number': record.user && record.user.phoneNumber,
      'User Id': record.userId,
      'Created by': record.createdBy,
    }
  })

  exportExcel({
    data,
    title: 'CRM Redeem code',
    type: 'xlsx',
  })
}

const CsUserCodeList = props => {
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        exporter={exporter}>
        <Datagrid>
          <UserDetailField label="User" />
          <TextField label="Code" source="codeString" />
          <FunctionField
            label="Recipient email"
            render={record => {
              const code = record.code
              if (!code) return ''
              return code.recipientEmail || code.invoiceEmail
            }}
          />
          <FunctionField
            label="Phone number"
            render={record => {
              const code = record.code
              if (!code) return ''
              if (code.payment_order && code.payment_order.phone) {
                return code.payment_order.phone
              }
              return null
            }}
          />
          <FunctionField
            label="User email"
            render={record => {
              return record.user && record.user.email
            }}
          />
          <FunctionField
            label="User phone number"
            render={record => {
              return record.user && record.user.phoneNumber
            }}
          />
          <ReferenceField
            label="Created by"
            source="createdBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CsUserCodeList
