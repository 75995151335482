import React, { Fragment } from 'react'

import {
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  minLength,
  maxValue,
  maxLength,
  minValue,
  FormDataConsumer,
  SimpleForm,
} from 'react-admin'

import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import CourseToolbar from '../../components/CourseToolbar'
import { validateRequired, validateUnique } from '../../utils/validate'
import CustomFileInput from '../../components/CustomFileInput'
import GridContainer from '../../components/GridContainer'
import { ROLES } from '../../components/constants'
import { PRICE_CHOICES } from './priceTiers'
import PeriodCommissionsInput from '../../components/PeriodCommissionsInput'
import RichTextInput from 'ra-input-rich-text'
import CustomTextInput from '../../components/CustomTextInput'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const feedbackRatingOptions = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
]

const CourseForm = props => {
  const { renderSampleFile, isCreating, permissions, ...rest } = props

  const validateCommission = [minValue(0), maxValue(100)]

  const validateDescription = [
    validateRequired('Description'),
    minLength(100),
    maxLength(2000, 'Do not enter more than 2000 characters'),
  ]

  const validateEpisodeOrder = [
    validateRequired('Order'),
    minValue(1),
    maxValue(1000),
  ]

  const validateFeedbackOrder = [
    validateRequired('Order'),
    minValue(1),
    maxValue(1000),
  ]

  return (
    <SimpleForm
      {...rest}
      toolbar={<CourseToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        {!isCreating && (
          <Fragment>
            <Grid container item xs={4}>
              <TextInput
                disabled
                fullWidth
                source="id"
                variant="standard"></TextInput>
            </Grid>
            <Grid container item xs={8}>
              <TextInput
                disabled
                fullWidth
                source="productId"
                variant="standard"></TextInput>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12}>
          <CustomTextInput
            source="title"
            fullWidth
            helperText={'Tối đa 50 kí tự; Lý tưởng < 35 kí tự'}
            validate={[
              validateRequired('Title'),
              maxLength(255, 'Do not enter more than 255 characters'),
            ]}
            variant="standard"
            showTextLength
          />
        </Grid>

        <Grid item xs={4}>
          <ReferenceArrayInput
            label="Primary categories"
            source="primaryCategoryIds"
            reference="categories"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: 'course',
              primary: true,
            })}
            validate={validateRequired('Primary categories')}>
            <AutocompleteArrayInput
              optionText="name"
              fullWidth
              required
              variant="standard"
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={8}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.primaryCategoryIds?.length) return null
              return (
                <ReferenceArrayInput
                  label="Secondary categories"
                  source="secondaryCategoryIds"
                  reference="categories"
                  filterToQuery={searchText => ({
                    name: { $iLike: `%${searchText}%` },
                    type: 'course',
                    primary: false,
                    parentId: formData.primaryCategoryIds,
                  })}>
                  <AutocompleteArrayInput
                    optionText="name"
                    fullWidth
                    required
                    variant="standard"
                  />
                </ReferenceArrayInput>
              )
            }}
          </FormDataConsumer>
        </Grid>

        <ArrayInput
          source="coaches"
          label="Mentors"
          fullWidth
          validate={[validateRequired('Mentors')]}>
          <SimpleFormIterator>
            <ReferenceInput
              label="Mentor"
              source="coachId"
              reference="coaches"
              perPage={1000}>
              <SelectInput
                optionText="name"
                options={{
                  fullWidth: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
            <CustomTextInput
              source="headline"
              label="Mentor Headline"
              fullWidth
              variant="standard"
              helperText={'Tối đa 70 kí tự, lý tưởng < 50 kí tự'}
              validate={[maxLength(70, 'Do not enter more than 70 characters')]}
              showTextLength
              useScoped
            />
            <RichTextInput
              source="description"
              label="Mentor Description"
              fullWidth
              variant="standard"
            />
          </SimpleFormIterator>
        </ArrayInput>

        <Grid item xs={6}>
          <ReferenceInput
            label="Publisher"
            source="publisherId"
            reference="users"
            filter={{
              role: 'publisher',
            }}
            perPage={1000}
            validate={validateRequired('Publisher')}>
            <SelectInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
            />
          </ReferenceInput>
        </Grid>

        {(permissions === ROLES.ADMIN || permissions === ROLES.QC) && (
          <>
            <Grid item xs={6}>
              <FormDataConsumer>
                {({ formData }) => {
                  if (!formData || !props.users[formData.publisherId])
                    return null
                  return (
                    <NumberInput
                      label={`Commission (0-100) / default: ${props.users[
                        formData.publisherId
                      ] &&
                        props.users[formData.publisherId].defaultCommission}%`}
                      source="commission"
                      fullWidth
                      validate={validateCommission}
                      variant="standard"
                    />
                  )
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item xs={12}>
              <PeriodCommissionsInput
                fullWidth
                variant="standard"
                label="Period commissions"
                source="commissions"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <SelectInput
            source="price"
            choices={PRICE_CHOICES}
            required
            fullWidth
            variant="standard"
            margin="normal"
            validate={validateRequired('Price')}
          />
        </Grid>

        <Grid item xs={12}>
          <RichTextInput
            source="coachDescription"
            fullWidth
            variant="standard"
            helperText={'Tối đa 3 dòng, mỗi dòng tối đa 70 kí tự'}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Số ISBN"
            source="isbnNumber"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="depositary"
            label="Nộp lưu chiểu"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextInput
            label="What you'll get"
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            helperText={'Lý tưởng < 250 kí tự'}
            validate={validateDescription}
            multiline
            showTextLength
          />
        </Grid>

        <Grid item xs={12}>
          <CustomFileInput
            source="teaserUrl"
            label="Teaser"
            accept="video/*"
            multiple={false}
            type="teaser"
            upload
            showProgress
            helperText={'Độ dài lý tưởng 30-60s'}>
            <CustomFileField
              source="src"
              title="Teaser"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            source="attachments"
            fullWidth
            variant="standard"
            validate={validateUnique('name')}>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  if (!formData || !formData.episodes) return null

                  return (
                    <SelectInput
                      label="Episode"
                      source={getSource('courseEpisodeId')}
                      fullWidth
                      variant="standard"
                      optionText="title"
                      choices={formData.episodes.filter(
                        course => course && course.id,
                      )}
                    />
                  )
                }}
              </FormDataConsumer>

              <TextInput
                source="name"
                label="Name"
                fullWidth
                variant="standard"
                validate={validateRequired('Title name')}
              />
              <CustomFileInput
                source="url"
                label="File"
                type="attachment"
                accept="text/*, image/*, application/msword, application/pdf"
                upload={true}
                validate={validateRequired('Audio file')}>
                <CustomFileField source="src" title="attachment" canDownload />
              </CustomFileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            source="coverImages"
            fullWidth
            helperText={'Tỉ lệ hình 2:3; Số lượng hình = 3'}
            validate={[validateRequired('coverImages')]}>
            <SimpleFormIterator>
              <ImageInput
                source="url"
                label="Cover image"
                accept="image/*"
                multiple={false}>
                <CustomImageField source="src" title="Cover image" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          {renderSampleFile ? (
            renderSampleFile(props)
          ) : (
            <CustomFileInput
              source="fileSampleUrl"
              label="Free Preview"
              accept="video/*"
              multiple={false}
              validate={validateRequired('Free Preview')}
              type="fileSample"
              upload
              showProgress>
              <CustomFileField
                source="src"
                title="Free Preview"
                canDownload={permissions === ROLES.ADMIN}
              />
            </CustomFileInput>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label="Youtube Video Id of Free Preview"
            source="sampleYoutubeVideoId"
            fullWidth
            variant="standard"
            helperText={'Example: xVMHErw0a0g'}
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData.sampleYoutubeVideoId) return

            return (
              <Grid item xs={12}>
                <iframe
                  title="Free Preview YouTube Video"
                  width="640"
                  height="360"
                  src={`https://www.youtube.com/embed/${formData.sampleYoutubeVideoId}`}
                  frameBorder="0"
                  allowFullScreen></iframe>
              </Grid>
            )
          }}
        </FormDataConsumer>
      </GridContainer>

      <ArrayInput
        source="episodes"
        fullWidth
        validate={[
          validateRequired('Episodes'),
          validateUnique('order', true),
        ]}>
        <SimpleFormIterator>
          <TextInput
            source="order"
            label="Order"
            variant="standard"
            validate={validateEpisodeOrder}
          />
          <CustomTextInput
            source="title"
            label="Title"
            fullWidth
            variant="standard"
            helperText={'Tối đa 85 kí tự, lý tưởng < 50 kí tự'}
            validate={[
              validateRequired('title'),
              maxLength(85, 'Do not enter more than 85 characters'),
            ]}
            showTextLength
            useScoped
          />
          <CustomTextInput
            source="shortDescription"
            label="Description"
            fullWidth
            variant="standard"
            rows={3}
            multiline
            validate={[maxLength(300, 'Do not enter more than 300 characters')]}
            showTextLength
          />
          <CustomFileInput
            source="videoUrl"
            label="File"
            type="episode"
            accept="video/*"
            upload={true}
            showProgress
            validate={validateRequired('Episode file')}>
            <CustomFileField
              source="src"
              title="episode"
              variant="standard"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            helperText="Tỉ lệ 2:3">
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput
        source="feedbacks"
        fullWidth
        validate={[validateUnique('order', true)]}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextInput
                      source={getSource('order')}
                      label="Order"
                      variant="standard"
                      validate={validateFeedbackOrder}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectInput
                      label="Rating"
                      source={getSource('rating')}
                      choices={feedbackRatingOptions}
                      required
                      variant="standard"
                      validate={validateRequired('Rating')}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomTextInput
                      source={getSource('username')}
                      label="User Name"
                      variant="standard"
                      validate={[validateRequired('User Name')]}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextInput
                      source={getSource('feedback')}
                      label="Feedback"
                      fullWidth
                      variant="standard"
                      required
                      validate={[
                        validateRequired('Feedback'),
                        maxLength(
                          2500,
                          'Do not enter more than 2500 characters',
                        ),
                      ]}
                      showTextLength
                      maxTextLength={2500}
                    />
                  </Grid>
                </Grid>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}

const mapStateToProps = state => {
  return {
    users: state.admin.resources.users.data,
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    null,
  )(CourseForm),
)
