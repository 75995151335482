import React from 'react'
import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  SelectInput,
  TextField,
} from 'react-admin'
import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'
import moment from 'moment/moment'
import { exportExcel } from '../../utils/exportExcel'

const CustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Filter"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const exporter = records => {
  const data = records.map(record => {
    return {
      'Transaction date':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'Order No': record.id,
      'Customer name': record.name,
      Phone: record.phone,
      Email: record.email,
      'Payment gateway':
        record.payment_gateway && record.payment_gateway.provider,
      'Product name': record.productName,
      'Product type': record.productType,
      Revenue: record.discount + record.amount,
      'Revenue from customer': record.amount,
      'Value of code Got It': record.discount,
      'Fonos commission fee (included VAT)': record.discount
        ? (record.discount * 3 * 1.1) / 100
        : 0,
      'Batch code from Got It':
        record.redeems && record.redeems.map(r => r.code).join(','),
    }
  })

  exportExcel({
    data,
    title: 'Redeem vouchers',
    type: 'xlsx',
  })
}

const RedeemVoucherList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<CustomFilter />}
      filterDefaultValues={{
        date: 'this_month',
      }}
      exporter={exporter}>
      <Datagrid>
        <DateField source="createdAt" label="Transaction date" />
        <TextField source="id" label="Order No" />
        <TextField source="name" label="Customer name" />
        <TextField source="phone" label="Phone" />
        <TextField source="email" label="Email" />
        <TextField source="payment_gateway.provider" label="Payment gateway" />
        <TextField source="productName" label="Product name" />
        <TextField source="productType" label="Product type" />
        <FunctionField
          label="Revenue"
          render={record => {
            return record.discount + record.amount
          }}
        />
        <TextField source="amount" label="Revenue from customer" />
        <TextField source="discount" label="Value of code Got It" />
        <FunctionField
          label="Fonos commission fee (included VAT)"
          render={record => {
            if (!record.discount) {
              return 0
            }
            return (record.discount * 3 * 1.1) / 100
          }}
        />
        <FunctionField
          label="Batch code from Got It"
          render={record => {
            if (record.redeems) {
              return record.redeems.map(r => r.code).join('\n')
            }
            return ''
          }}
        />
      </Datagrid>
    </List>
  )
}

export default RedeemVoucherList
