import React from 'react'
import { ReferenceField, TextField } from 'react-admin'

const CustomTextField = props => {
  const { record, source, reference, label, referenceField } = props
  if (!record[source]) return <span>{'-'}</span>

  return (
    <ReferenceField
      source={source}
      reference={reference}
      label={label}
      {...props}>
      <TextField source={referenceField} />
    </ReferenceField>
  )
}

export default CustomTextField
