import React from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import Grid from '@material-ui/core/Grid'

const CsUserCodeCreate = props => {
  return (
    <Create undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput
              label="Username"
              source="userId"
              reference="users"
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
              })}
              validate={validateRequired('user')}>
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput
              label="Code"
              source="codeId"
              reference="codes"
              filterToQuery={searchText => ({
                code: searchText,
                status: true,
              })}
              validate={validateRequired('code')}>
              <AutocompleteInput
                optionText="code"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsUserCodeCreate
