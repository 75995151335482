import React from 'react'
import {
  Create,
  SimpleForm,
  SelectInput,
  DateInput,
  FormDataConsumer,
  SelectArrayInput,
} from 'react-admin'
import GridContainer from '../../components/GridContainer'
import Grid from '@material-ui/core/Grid'

const CrmRevenueReportCreate = props => {
  return (
    <Create undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <SelectInput
              label="Date range"
              source="range"
              fullWidth
              choices={[
                { id: 'today', name: 'Today' },
                { id: 'last_week', name: 'Last week' },
                { id: 'last_month', name: 'Last month' },
                { id: 'last_3_months', name: 'Last 3 month' },
                { id: 'custom', name: 'Custom' },
              ]}
              alwaysOn
              allowEmpty={false}
            />
          </Grid>

          <FormDataConsumer>
            {({ formData }) => {
              if (!formData || formData.range !== 'custom') return null
              return (
                <Grid item xs={6}>
                  <DateInput
                    fullWidth
                    label="From date"
                    source="fromDate"
                    alwaysOn
                  />
                </Grid>
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData || formData.range !== 'custom') return null
              return (
                <Grid item xs={6}>
                  <DateInput
                    fullWidth
                    label="To date"
                    source="toDate"
                    alwaysOn
                  />
                </Grid>
              )
            }}
          </FormDataConsumer>
          <Grid item xs={12}>
            <SelectArrayInput
              label="Providers"
              source="providers"
              fullWidth
              choices={[
                { id: 'APP_STORE', name: 'App Store' },
                { id: 'PLAY_STORE', name: 'Play Store' },
              ]}
              defaultValue={['APP_STORE', 'PLAY_STORE']}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CrmRevenueReportCreate
