import React, { useMemo } from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import { ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const UserFilter = props => {
  return (
    <FilterComponent {...props}>
      <TextInput label="Email" source="email" alwaysOn />
      <TextInput label="Name" source="name" alwaysOn />
      <ReferenceInput label="Role" source="role" reference="roles" alwaysOn>
        <SelectInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
    </FilterComponent>
  )
}

const UserList = props => {
  const filterDefaultValues = useMemo(() => {
    switch (props.permissions) {
      case ROLES.ADMIN:
        return {
          role: ROLES.PUBLISHER,
        }
      case ROLES.AFFILIATE_MANAGER:
        return {
          role: ROLES.AFFILIATE,
        }

      default:
        return { role: ROLES.PUBLISHER }
    }
  }, [props])

  return (
    <List
      {...props}
      filters={<UserFilter permissions={props.permissions} />}
      filterDefaultValues={filterDefaultValues}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default UserList
