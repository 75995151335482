import React from 'react'
import { DeleteButton } from 'react-admin'
import { ROLES } from './constants'

const DeleteBtn = ({ record, permissions, undoable = false }) => {
  if (!record || permissions !== ROLES.ADMIN) return null
  return <DeleteButton label="Delete" record={record} undoable={undoable} />
}

export default DeleteBtn
