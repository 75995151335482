import React, { Fragment, useEffect } from 'react'
import feathersClient from './feathersClient'
import { Helmet } from 'react-helmet'

const withAwsWaf = Component => {
  const WrapperComponent = props => {
    const [loading, setLoading] = React.useState(
      process.env.REACT_APP_ENV === 'production',
    )
    // Implement AWS WAF for production
    useEffect(() => {
      if (process.env.REACT_APP_ENV !== 'production') return

      document.getElementById('aws-waf').addEventListener('load', () => {
        feathersClient.init()
        setLoading(false)
      })

      return () => {
        document.getElementById('aws-waf').removeEventListener('load')
      }
    }, [])

    return (
      <Fragment>
        {process.env.REACT_APP_ENV === 'production' && (
          <Helmet>
            <script
              id="aws-waf"
              type="text/javascript"
              src="https://e9deaf1d52ad.ap-southeast-1.sdk.awswaf.com/e9deaf1d52ad/9e91bc5155f7/challenge.js"
            />
          </Helmet>
        )}
        {!loading && <Component {...props} />}
      </Fragment>
    )
  }

  return WrapperComponent
}

export default withAwsWaf
