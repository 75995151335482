import React, { Fragment } from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
  DateInput,
} from 'react-admin'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import { CODE_TYPES, CODE_SOURCE } from '../../components/constants'

const ApiCodeForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={4}>
          <SelectInput
            label="Type"
            source="type"
            choices={CODE_TYPES}
            fullWidth
            variant="standard"
            allowEmpty={false}
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null

            switch (formData.type) {
              case 'book':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Book"
                        source="bookId"
                        reference="books"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}>
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )

              case 'collection':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Collection"
                        source="collectionCodeId"
                        reference="collection-codes"
                        filterToQuery={searchText => ({
                          name: { $iLike: `%${searchText}%` },
                        })}>
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )
              default:
                return null
            }
          }}
        </FormDataConsumer>
        <Grid item xs={6}>
          <SelectInput
            source="source"
            choices={CODE_SOURCE}
            defaultValue="web"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Source')}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            source="expiredAt"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Expired at')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

const mapStateToProps = state => {
  return {
    books: state.admin.resources.books.data,
  }
}

export default connect(
  mapStateToProps,
  null,
)(ApiCodeForm)
