import React from 'react'
import { List, Datagrid, ShowButton, DateField, TextField } from 'react-admin'

const PromotionNotificationList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField label="Promotion Id" source="promotionId" />
        <TextField label="Channel" source="channel" />
        <DateField label="Schedule at" source="scheduleAt" />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PromotionNotificationList
