import React from 'react'
import { List, Datagrid, EditButton, TextField } from 'react-admin'
import ListStatusField from '../../components/ListStatusField'

const LandingPageList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField label="Id" source="id" />
        <TextField label="Title" source="name" />
        <TextField label="Status" source="status" />
        <ListStatusField resource="landing-page" label="Status" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default LandingPageList
