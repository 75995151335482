export const BOOK_STATUSES = {
  REVIEW: 'Under review',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  REJECTED: 'Rejected',
}

export const ENGLISH_BOOK_STATUSES = {
  REVIEW: 'Under review',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  REJECTED: 'Rejected',
}

export const COURSE_STATUSES = {
  REVIEW: 'Under review',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  REJECTED: 'Rejected',
}

export const PODCAST_STATUS_DISPLAY = {
  UNDER_REVIEW: 'Under review',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  DISABLED: 'Disabled',
}

export const PODCASTER_STATUS = {
  UNDER_REVIEW: 'UNDER_REVIEW',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
}

export const CHANNEL_STATUS = {
  UNDER_REVIEW: 'UNDER_REVIEW',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
}

export const EPISODE_STATUS = {
  UNDER_REVIEW: 'UNDER_REVIEW',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  DISABLED: 'DISABLED',
}

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  EDITOR: 'editor',
  PUBLISHER: 'publisher',
  AFFILIATE: 'affiliate',
  AFFILIATE_MANAGER: 'affiliate_manager',
  ACCOUNTANT: 'accountant',
  QC: 'qc',
  MARKETER: 'marketer',
  CUSTOMER_SERVICE: 'custom_service',
  CPSO: 'cpso',
  MARKETING_MANAGER: 'marketing_manager',
  CONTENT_EDITOR: 'content_editor',
  BOOK_EDITOR: 'book_editor',
  STATISTICAL_STAFF: 'statistical_staff',
  HEAD_OF_CUSTOMER_SERVICE: 'head_of_customer_service',
  FINANCE_DIRECTOR: 'finance-director',
}

export const SUBSCRIPTION = {
  YEARLY_PRICE: 899000,
  MONTHLY_PRICE: 99000,
}

export const CODE_TYPES = [
  { id: 'one_month_subscription', name: '1-Month Subscription' },
  { id: 'one_month_subscription_pro', name: '1-Month Subscription Pro' },
  { id: 'three_month_subscription', name: '3-Month Subscription' },
  { id: 'three_month_subscription_pro', name: '3-Month Subscription Pro' },
  { id: 'one_year_subscription', name: '1-Year Subscription' },
  { id: 'one_year_subscription_pro', name: '1-Year Subscription Pro' },
  { id: 'three_year_subscription', name: '3-Year Subscription' },
  { id: 'book', name: 'Book' },
  { id: 'collection', name: 'Collection' },
]

export const CODE_TYPE_NAME = {
  book: 'Book',
  one_month_subscription: '1-Month Subscription',
  one_month_subscription_pro: '1-Month Subscription Pro',
  three_month_subscription: '3-Month Subscription',
  three_month_subscription_pro: '3-Month Subscription Pro',
  one_year_subscription: '1-Year Subscription',
  one_year_subscription_pro: '1-Year Subscription Pro',
  three_year_subscription: '3-Year Subscription',
  collection: 'Collection',
}

export const FILTER_DATE = [
  { id: 'this_month', name: 'This month' },
  { id: 'last_month', name: 'Last month' },
  { id: 'all', name: 'All' },
  { id: 'custom', name: 'Choose date' },
]

export const CODE_SOURCE = [
  { id: 'web', name: 'Website' },
  { id: 'tiki', name: 'Tiki' },
  { id: 'shopee', name: 'Shopee' },
  { id: 'tiktok_shop', name: 'TikTok Shop' },
]

export const getEnumBookStatuses = () => Object.values(BOOK_STATUSES)

export const PUBLISHER_TYPE = {
  DOMESTIC: 'DOMESTIC',
  FOREIGN: 'FOREIGN',
}

export const FILTER_MONTH = [
  { id: 1, name: '01' },
  { id: 2, name: '02' },
  { id: 3, name: '03' },
  { id: 4, name: '04' },
  { id: 5, name: '05' },
  { id: 6, name: '06' },
  { id: 7, name: '07' },
  { id: 8, name: '08' },
  { id: 9, name: '09' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
]

export const FILTER_YEAR = [
  { id: 2024, name: '2024' },
  { id: 2023, name: '2023' },
]

export const COLLECTION_TYPES = [
  { id: 'book', name: 'Book', entity: 'books' },
  { id: 'course', name: 'Course', entity: 'courses' },
]

export const COLLECTION_TYPES_NAME = {
  book: 'Book',
  course: 'Course',
}

export const CHALLENGE_REWARD_TYPES = [
  { id: 'book', name: 'Book', entity: 'books' },
]

export const CHALLENGE_REWARD_TYPE_NAME = {
  book: 'Book',
}
