import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  TextInput,
} from 'react-admin'

import CustomSwitchField from '../../components/CustomSwitchField'
import FilterComponent from '../../components/Filter'

const ArticleFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </FilterComponent>
)

const ArticleList = props => (
  <List
    {...props}
    filters={<ArticleFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CustomSwitchField
        resource="articles"
        label="Is Free"
        switchField="isFree"
      />
      <CustomSwitchField
        resource="articles"
        label="Status"
        switchField="status"
      />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default ArticleList
