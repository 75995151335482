import React from 'react'
import { Create } from 'react-admin'

import MeditationQuoteForm from './MeditationQuoteForm'

const MeditationQuoteCreate = props => (
  <Create {...props}>
    <MeditationQuoteForm permissions={props.permissions} />
  </Create>
)

export default MeditationQuoteCreate
