import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import {
  TextInput,
  SimpleForm,
  CREATE,
  showNotification,
  Notification,
  email,
  SaveButton,
  Toolbar,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import { validateRequired } from '../utils/validate'
import getDataProvider from '../dataProvider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url('https://source.unsplash.com/random/1600x900/daily')`,
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
  })

const CustomToolBar = props => (
  <Toolbar
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '1rem 0',
    }}>
    <SaveButton label="Reset password" />
    <Button>
      <Link to="/login">Login</Link>
    </Button>
  </Toolbar>
)

const ForgotPasswordPage = props => {
  const handleSubmit = data => {
    const { showNotification } = props
    return getDataProvider()(CREATE, 'authentication/sendResetPassword', {
      data: {
        email: data.email,
      },
    })
      .then(() => {
        showNotification(
          'Send request successfully. Please check your email inbox.',
        )
      })
      .catch(error => {
        showNotification(error.message, 'warning')
      })
  }
  const { classes } = props

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardHeader
          title="Reset password"
          subheader="Enter your email to reset your password."
        />
        <CardContent>
          <SimpleForm save={handleSubmit} toolbar={<CustomToolBar />}>
            <TextInput
              label="Email"
              source="email"
              type="email"
              fullWidth
              validate={[validateRequired('Email'), email()]}
            />
          </SimpleForm>
        </CardContent>
      </Card>
      <Notification />
    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(
    null,
    { showNotification },
  ),
)(ForgotPasswordPage)
