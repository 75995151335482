import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'
import UserDetailField from '../../components/UserDetailField'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import moment from 'moment'
import { exportExcel } from '../../utils/exportExcel'
import FilterComponent from '../../components/Filter'
import { ROLES } from '../../components/constants'

const PURCHASE_TYPE_NAME = {
  free: 'Free',
  direct: 'Direct',
  credit: 'Credit',
  code: 'Code',
  membership_included: 'Membership Included',
  manual: 'Manual',
}
const TYPE_CREDIT_NAME = {
  monthly: 'Monthly',
  annually: 'Annually',
  bundle_3_v2: 'Bundle 3',
  bundle_5_v2: 'Bundle 5',
  bundle_10_v2: 'Bundle 10',
  bundle: 'Bundle 3',
  bundle_5: 'Bundle 5',
  bundle_10: 'Bundle 10',
  free: 'Free',
}

const CsUserBookFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Approved By"
      source="createdBy"
      reference="users"
      filter={{
        role: [ROLES.ADMIN, ROLES.MARKETING_MANAGER, ROLES.STATISTICAL_STAFF],
      }}
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>

    <SelectInput
      label="Filter"
      source="date"
      choices={[
        { id: 'all', name: 'All' },
        { id: 'this_month', name: 'This month' },
        { id: 'last_month', name: 'Last month' },
        { id: 'custom', name: 'Choose date' },
      ]}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomEntityField = ({ record }) => {
  if (record && record.type === 'book') {
    return (
      <ReferenceField label="Book" source="bookId" reference="books">
        <TextField source="title" />
      </ReferenceField>
    )
  }
  if (record && record.type === 'ebook') {
    return (
      <ReferenceField label="Ebook" source="ebookId" reference="ebooks">
        <TextField source="title" />
      </ReferenceField>
    )
  }
  return '-'
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Username: record.user.username,
      type: record.type,
      Book: record.type === 'book' ? record.book.title : record.ebook.title,
      BookId: record.type === 'book' ? record.book.id : record.ebook.id,
      'Purchase type': PURCHASE_TYPE_NAME[record.purchaseType] || '',
      'Credit type': TYPE_CREDIT_NAME[record.creditType] || '',
      Status: record.status,
      Reason: record.reason,
      'Created At': moment(record.createdAt).format('MM/DD/YYYY'),
      'Created By': record.createdUser.name,
      'Approved At': moment(record.updatedAt).format('MM/DD/YYYY'),
      'Approved By': record.updatedUser.name,
    }
  })
  exportExcel({
    data,
    title: `cs_user_books_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const CsUserBookList = props => {
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        exporter={exporter}
        filters={<CsUserBookFilter />}>
        <Datagrid>
          <UserDetailField label="User" />
          <TextField source="type" />
          <CustomEntityField label="Book/Ebook" />
          <FunctionField
            label="Purchase type"
            source="purchaseType"
            render={record => PURCHASE_TYPE_NAME[record.purchaseType] || '-'}
          />
          <FunctionField
            label="Credit type"
            source="creditType"
            render={record => TYPE_CREDIT_NAME[record.creditType] || '-'}
          />
          <TextField source="reason" />
          <ReferenceField
            label="Created by"
            source="createdBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Updated by"
            source="updatedBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ApproveStatus label="Status" />
          <ApproveButtons
            permissions={props.permissions}
            service="cs-user-books"
          />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CsUserBookList
