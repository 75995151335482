import React from 'react'
import Grid from '@material-ui/core/Grid'

import {
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const BANNER_TYPES = [
  { id: 'book', name: 'Book' },
  { id: 'book_summary', name: 'Book Summary' },
  { id: 'author', name: 'Author' },
  { id: 'podcast_channel', name: 'Channel' },
  { id: 'podcast_episode', name: 'Episode' },
]

const BannerForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <SelectInput
            source="type"
            choices={BANNER_TYPES}
            fullWidth
            required
            variant="standard"
            validate={validateRequired('Type')}
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            const type = formData.type

            switch (type) {
              case 'book': {
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Book"
                      required
                      source="bookId"
                      reference="books"
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                      })}
                      validate={validateRequired('Book')}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  </Grid>
                )
              }
              case 'book_summary': {
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Book Summary"
                      required
                      source="bookSummaryId"
                      reference="book-summaries"
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                      })}
                      validate={validateRequired('Book')}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  </Grid>
                )
              }
              case 'podcast_channel': {
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Channel"
                      required
                      source="entityId"
                      reference="channels"
                      filterToQuery={searchText => ({
                        name: { $iLike: `%${searchText}%` },
                      })}
                      validate={validateRequired('Channel')}>
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  </Grid>
                )
              }
              case 'podcast_episode': {
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Episode"
                      required
                      source="entityId"
                      reference="episodes"
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                      })}
                      validate={validateRequired('Episode')}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  </Grid>
                )
              }
              case 'author': {
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Author"
                      required
                      source="entityId"
                      reference="authors"
                      filterToQuery={searchText => ({
                        name: { $iLike: `%${searchText}%` },
                      })}
                      validate={validateRequired('Author')}>
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  </Grid>
                )
              }
            }
          }}
        </FormDataConsumer>
      </GridContainer>
    </SimpleForm>
  )
}

export default BannerForm
