import React from 'react'
import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  SelectInput,
  Tab,
  TabbedShowLayout,
} from 'react-admin'
import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'
import moment from 'moment'
import { exportExcel } from '../../utils/exportExcel'

const CourseCustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Filter"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomUserField = ({ record }) => {
  const { userId } = record
  return (
    <a style={{ color: '#0000EE' }} href={`#/user-details/${userId}/show`}>
      {userId}
    </a>
  )
}

const getSuggestionField = (record, field) => {
  if (!record || !record.suggestion) return null
  const suggestion = JSON.parse(record.suggestion)
  return suggestion[field]
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'User Id': record.userId,
      topic: getSuggestionField(record, 'topic'),
      mentor: getSuggestionField(record, 'mentor'),
    }
  })

  exportExcel({
    data,
    title: 'PodCourse suggestions',
    type: 'xlsx',
  })
}

const CourseSuggestionList = props => (
  <List
    {...props}
    filters={<CourseCustomFilter />}
    filterDefaultValues={{ entity: 'course' }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    exporter={exporter}>
    <Datagrid>
      <DateField source="createdAt" />
      <CustomUserField label="User" />
      <FunctionField
        source="suggestion"
        label="Chủ đề mong muốn"
        sortable={false}
        render={record => {
          return getSuggestionField(record, 'topic')
        }}
      />
      <FunctionField
        source="suggestion"
        label="Mentor mong muốn"
        sortable={false}
        render={record => {
          return getSuggestionField(record, 'mentor')
        }}
      />
    </Datagrid>
  </List>
)

const SuggestionList = () => (
  <TabbedShowLayout>
    <Tab label="PodCourse">
      <CourseSuggestionList />
    </Tab>
  </TabbedShowLayout>
)

export default SuggestionList
