import React from 'react'
import { Create } from 'react-admin'

import CollectionForm from './ChallengeRewardForm'

const ChallengeRewardCreate = props => (
  <Create {...props}>
    <CollectionForm />
  </Create>
)

export default ChallengeRewardCreate
