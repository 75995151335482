import React from 'react'
import { Edit } from 'react-admin'
import SlideshowForm from './SlideshowForm'

const SlideshowEdit = props => (
  <Edit undoable={false} {...props}>
    <SlideshowForm />
  </Edit>
)

export default SlideshowEdit
