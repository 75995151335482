import React, { useMemo, useState } from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import debounce from 'lodash/debounce'

const ListStatusField = props => {
  const { resource, record, dataProvider } = props
  const [status, setStatus] = useState(record.status)

  const onChange = useMemo(() => {
    return debounce(
      status =>
        dataProvider(
          UPDATE,
          resource,
          { id: record.id, data: { status } },
          {
            onSuccess: {
              notification: { body: 'Update successfully', level: 'info' },
              redirectTo: `/${resource}`,
            },
            onFailure: {
              notification: {
                body: `Error: ${resource} not updated`,
                level: 'warning',
              },
            },
          },
        ),
      500,
    )
  }, [])

  const handleChange = () => {
    setStatus(prev => {
      onChange(!prev)
      return !prev
    })
  }

  return (
    <FormControlLabel
      control={<Switch checked={status} onChange={handleChange} />}
    />
  )
}

export default withDataProvider(ListStatusField)
