/* eslint-disable react/display-name */
import React, { useEffect, useState, Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import debounce from 'lodash/debounce'

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  withDataProvider,
  GET_ONE,
  SelectInput,
  ReferenceInput,
  DateInput,
  showNotification,
  ExportButton,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

import { parseDate } from '../../utils/parseDate'
import { exportExcel } from '../../utils/exportExcel'
import { formatCurrency } from '../../utils/formatCurrency'
import { SUBSCRIPTION } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const Actions = ({ resource, currentSort, filterValues, exporter }) => {
  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  )
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      Code: record.code,
      Plan: record.plan,
      Price: record.amount,
      Date: record.createdAt,
      Commission: record.commission,
      Amount: formatCurrency.format(
        ((record.commission / 100) * SUBSCRIPTION.YEARLY_PRICE).toFixed(0),
      ),
      TransactionId: record.originalTransactionId,
      PaymentMethod: record.paymentMethod,
    }
  })

  exportExcel({
    data,
    title: 'Dashboard',
    type: 'xlsx',
  })
}

const CustomDatagrid = withDataProvider(props => {
  const [revenue, setRevenue] = useState(0)
  const [totalInstall, setTotalInstall] = useState(0)
  const [totalInstallAmount, setTotalInstallAmount] = useState(0)
  const [isShowing = {}, setIsShowing] = useState({
    showFD: false,
    showTD: false,
  })

  const handleSetRevenue = () => {
    const { dataProvider } = props

    dataProvider(GET_ONE, 'affiliate-dashboard-revenue', {
      id: null,
      date: props.filterValues && props.filterValues.date,
      affiliateId: props.filterValues && props.filterValues.affiliateId,
      fromDate: props.filterValues && props.filterValues.fromDate,
      toDate: props.filterValues && props.filterValues.toDate,
    })
      .then(({ data }) => setRevenue(data.result))
      .catch(console.log)
    dataProvider(GET_ONE, 'affiliate-dashboard-install', {
      id: null,
      date: props.filterValues && props.filterValues.date,
      affiliateId: props.filterValues && props.filterValues.affiliateId,
      fromDate: props.filterValues && props.filterValues.fromDate,
      toDate: props.filterValues && props.filterValues.toDate,
    })
      .then(({ data }) => {
        setTotalInstall(data.totalInstall)
        setTotalInstallAmount(data.totalInstallAmount)
      })
      .catch(console.log)
  }

  useEffect(() => {
    if (isShowing.showFD)
      setTimeout(() => setIsShowing({ ...isShowing, showFD: false }), 5000)
    if (isShowing.showTD)
      setTimeout(() => setIsShowing({ ...isShowing, showTD: false }), 5000)
  }, [isShowing])

  useEffect(() => {
    const debounced = debounce(() => {
      const { filterValues, showNotification } = props
      if ('custom' !== filterValues.date) {
        handleSetRevenue()
      } else {
        const fromDate = parseDate(filterValues.fromDate, 'YYYY-MM-DD'),
          toDate = parseDate(filterValues.toDate, 'YYYY-MM-DD'),
          presentDate = parseDate(new Date(), 'YYYY-MM-DD')
        if (fromDate > toDate && !isShowing.showFD) {
          showNotification(`End date isn't less than start date`)
          setIsShowing({ ...isShowing, showFD: true })
        } else if (toDate > presentDate && !isShowing.showTD) {
          showNotification(`End date isn't larger than present date`)
          setIsShowing({ ...isShowing, showTD: true })
        } else {
          handleSetRevenue()
        }
      }
    }, 1000)
    debounced.call()
  }, [props.filterValues])

  const PlanTextField = useCallback(({ record = {} }) => {
    const { productId } = record
    const plan = productId.split('.').pop()
    return <span>{plan}</span>
  }, [])

  const CommissionAmountField = useCallback(({ record = {} }) => {
    const { commission } = record
    const amount = ((commission / 100) * SUBSCRIPTION.YEARLY_PRICE).toFixed(0)
    return <span>{formatCurrency.format(amount.toString())}</span>
  }, [])

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>Total install: {totalInstall}</h3>
          <h3 style={{ marginLeft: 12 }}>
            Total install amount: {formatCurrency.format(totalInstallAmount)}
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>
            Total: {formatCurrency.format(revenue)}
          </h3>
        </Grid>
      </Grid>
      <Datagrid {...props}>
        <TextField source="id" />
        <TextField source="code" />
        <PlanTextField source="Plan" />
        <NumberField source="amount" label="Price" />
        <DateField source="createdAt" label="Date" />
        <NumberField source="commission" />
        <CommissionAmountField source="amount" />
        <TextField source="originalTransactionId" label="Transaction Id" />
        <TextField source="paymentMethod" label="Payment method" />
      </Datagrid>
    </Fragment>
  )
})

const CustomFilter = props => {
  return (
    <FilterComponent {...props} styles={{ marginLeft: '12px' }}>
      <ReferenceInput
        label="Affiliate name"
        source="affiliateId"
        reference="users"
        filter={{ role: 'affiliate' }}
        alwaysOn
        allowEmpty>
        <SelectInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === props.filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === props.filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const DashboardAdmin = props => {
  const [initProps, setInitProps] = useState({
    basePath: '/',
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    location: { pathname: '/', search: '', hash: '', state: undefined },
    match: { path: '/', url: '/', isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: 'affiliate-purchase-histories',
    perPage: 10,
    exporter,
  })

  useEffect(() => {
    const { search } = props.location
    if (search) {
      let queryParams = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/\?/g, '')
            .replace(/%3A/g, '":"')
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      )
      let perPage = queryParams.perPage
      let page = queryParams.page
      let filter = queryParams.filter

      const newProps = { ...initProps }
      newProps.perPage = +perPage
      newProps.page = +page
      newProps.location = props.location
      newProps.filter = filter
      setInitProps(newProps)
    }
  }, [props.location])

  return (
    <List
      {...initProps}
      title="Affiliate dashboard"
      bulkActionButtons={false}
      filters={<CustomFilter />}
      actions={<Actions />}
      filterDefaultValues={{
        role: 'affiliate',
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment()
          .endOf('day')
          .format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <CustomDatagrid showNotification={props.showNotification} />
    </List>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => dispatch(showNotification(message)),
  }
}
export default connect(
  null,
  mapDispatchToProps,
)(DashboardAdmin)
