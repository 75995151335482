import React from 'react'
import { Edit } from 'react-admin'

import EbookForm from './EbookForm'

const EbookEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <EbookForm permissions={props.permissions} />
    </Edit>
  )
}

export default EbookEdit
