import React, { useState } from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { EPISODE_STATUS, PODCAST_STATUS_DISPLAY } from './constants'
import get from 'lodash/get'

const EpisodeStatusField = props => {
  const { record, dataProvider, disabled } = props
  const [status, setStatus] = useState(record.status)

  const handleChange = async () => {
    const data = {
      status:
        status === EPISODE_STATUS.PUBLISHED
          ? EPISODE_STATUS.UNPUBLISHED
          : EPISODE_STATUS.PUBLISHED,
    }

    const response = await dataProvider(
      UPDATE,
      'episodes',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Episode updated', level: 'info' },
          redirectTo: '/episodes',
        },
        onFailure: {
          notification: {
            body: 'Error: Episode not updated',
            level: 'warning',
          },
        },
      },
    )
    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
    }
  }

  return [
    EPISODE_STATUS.PUBLISHED,
    EPISODE_STATUS.UNPUBLISHED,
    EPISODE_STATUS.UNDER_REVIEW,
  ].includes(record.status) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === EPISODE_STATUS.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={PODCAST_STATUS_DISPLAY[status]}
    />
  ) : (
    PODCAST_STATUS_DISPLAY[status]
  )
}

export default withDataProvider(EpisodeStatusField)
