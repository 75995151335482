import React, { Fragment, useEffect, useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateInput,
  SelectInput,
  withDataProvider,
  GET_ONE,
} from 'react-admin'
import moment from 'moment'
import FilterComponent from '../../components/Filter'

const CustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Filter"
      source="date"
      choices={[
        { id: 'all', name: 'All' },
        { id: 'this_month', name: 'This month' },
        { id: 'last_month', name: 'Last month' },
        { id: 'custom', name: 'Choose date' },
      ]}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomDataGrid = withDataProvider(({ dataProvider, ...props }) => {
  const [data, setData] = useState({
    newAccounts: 0,
    referralReceiveBooks: 0,
  })

  useEffect(() => {
    dataProvider(GET_ONE, 'referral-dashboard-analytic', {
      id: null,
      date: props.filterValues && props.filterValues.date,
      fromDate: props.filterValues && props.filterValues.fromDate,
      toDate: props.filterValues && props.filterValues.toDate,
    })
      .then(({ data }) => setData(data))
      .catch(console.log)
  }, [props.filterValues])

  return (
    <Fragment>
      <h4 style={{ marginLeft: 12 }}>New account: {data.newAccounts}</h4>
      <h4 style={{ marginLeft: 12 }}>
        Referral account received book: {data.referralReceiveBooks}
      </h4>
      <Datagrid {...props}>
        <TextField source="id" />
        <TextField source="title" />
        <NumberField source="count" label="Total books given" />
        <TextField />
      </Datagrid>
    </Fragment>
  )
})

const ReferralList = props => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      bulkActionButtons={false}
      exporter={false}
      filterDefaultValues={{
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment()
          .endOf('day')
          .format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <CustomDataGrid />
    </List>
  )
}

export default ReferralList
