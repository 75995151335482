import React from 'react'
import { Edit } from 'react-admin'

import EnglishBookForm from './EnglishBookForm'

const EnglishBookEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <EnglishBookForm permissions={props.permissions} />
    </Edit>
  )
}

export default EnglishBookEdit
