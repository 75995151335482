import React from 'react'
import { Edit } from 'react-admin'
import ArticleForm from './ArticleForm'

const ArticleEdit = props => (
  <Edit undoable={false} {...props}>
    <ArticleForm editing />
  </Edit>
)

export default ArticleEdit
