import React from 'react'
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import Grid from '@material-ui/core/Grid'

const CsRevertCodeCreate = props => {
  return (
    <Create undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput
              label="Code"
              source="codeId"
              reference="codes"
              filterToQuery={searchText => ({
                code: searchText,
                status: true,
              })}
              validate={validateRequired('code')}>
              <AutocompleteInput
                optionText="code"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="reason"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Reason')}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsRevertCodeCreate
