import React from 'react'
import { FormDataConsumer, TextInput } from 'react-admin'
import get from 'lodash/get'

const CustomTextInput = ({
  source,
  showTextLength,
  maxTextLength,
  ...props
}) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <>
            <TextInput source={source} {...props} />
            {showTextLength ? (
              <p
                style={{
                  fontSize: 12,
                  color: '#777',
                  paddingTop: 0,
                  marginTop: 0,
                }}>
                Số ký tự: {get(formData, source)?.length || 0}
                {maxTextLength ? `/${maxTextLength}` : ''}
              </p>
            ) : null}
          </>
        )
      }}
    </FormDataConsumer>
  )
}

export default CustomTextInput
