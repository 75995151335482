import React from 'react'
import get from 'lodash/get'

import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  useGetList,
  DateInput,
  RichTextField,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const channelNameMapping = {
  email: 'Email',
  pushNotification: 'Push Notification',
}

const ChannelField = ({ promotionId, selectedChannel, isShow }) => {
  const { data, loading } = useGetList('promotion-configs')
  if (loading) return null

  const promotion = Object.values(data).find(({ id }) => id === promotionId)
  if (!promotion) return null

  const availableChannels = get(promotion, 'notificationConfig.channel', [])
  const selectedChannelData = availableChannels.find(
    ({ name }) => name === selectedChannel,
  )

  return (
    <>
      <SelectInput
        source="channel"
        disabled={isShow}
        choices={availableChannels.map(ele => ({
          ...ele,
          name: channelNameMapping[ele.name],
          id: ele.name,
        }))}
      />
      {selectedChannelData && (
        <>
          <div>Channel config preview: </div>
          <div>{JSON.stringify(selectedChannelData)}</div>
        </>
      )}
    </>
  )
}

const PromotionNotificationForm = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput
            label="Promotion"
            source="promotionId"
            reference="promotion-configs"
            validate={validateRequired('Promotion Id')}>
            <SelectInput
              disabled={props.isShow}
              optionText="id"
              fullWidth
              required
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData || !formData.promotionId) return null
            return (
              <>
                <Grid item xs={12}>
                  <ChannelField
                    isShow={props.isShow}
                    promotionId={formData.promotionId}
                    selectedChannel={formData.channel}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateInput
                    disabled={props.isShow}
                    source="scheduleAt"
                    label="Schedule at:"
                  />
                </Grid>
              </>
            )
          }}
        </FormDataConsumer>
        {props.isShow && (
          <Grid item xs={12}>
            <RichTextField source="result" label="Result" />
          </Grid>
        )}
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(PromotionNotificationForm)
