/* eslint-disable react/display-name */
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { usePermissions } from 'react-admin'
import DashboardAffiliate from './DashboardAffiliate'
import DashboardAdmin from './DashboardAdmin'
import DashboardAffiliateManager from './DashboardAffiliateManager'

import { ROLES } from '../constants'
import DashboardPublisher from './DashboardPublisher'

const Dashboard = props => {
  const renderContent = () => {
    switch (props.permissions) {
      case ROLES.PUBLISHER: {
        return <DashboardPublisher {...props} />
      }
      case ROLES.ADMIN:
      case ROLES.ACCOUNTANT:
      case ROLES.STATISTICAL_STAFF:
      case ROLES.FINANCE_DIRECTOR: {
        return <DashboardAdmin {...props} />
      }

      case ROLES.AFFILIATE:
        return <DashboardAffiliate {...props} />

      case ROLES.AFFILIATE_MANAGER:
        return <DashboardAffiliateManager {...props} />

      default:
        return <CardContent>Welcome back to Fonos</CardContent>
    }
  }
  return <Card>{renderContent()}</Card>
}

export default ({ location, ...rest }) => {
  const { permissions } = usePermissions()

  return <Dashboard permissions={permissions} location={location} {...rest} />
}
