import React, { Fragment, useEffect, useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  ReferenceInput,
  DateInput,
  SelectInput,
  withDataProvider,
  GET_ONE,
  ReferenceField,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'
import { exportExcel } from '../../utils/exportExcel'
import CustomTextField from '../../components/CustomTextField'

export const PAYMENT_TYPE = {
  ONE_TIME: 'One time',
  SUBSCRIPTION: 'Subscription',
}

const ExportButton = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      Code: record.code,
      'Product Name': record.productName,
      'Product Type': record.productType,
      'Payment Type': PAYMENT_TYPE[record.paymentType],
      Price: record.amount,
      Date: record.createdAt,
      'Payment method': record.provider,
      'User Id': record.userId,
      Name: record.name,
      'Phone Number': record.phone,
      Email: record.email,
      'Employee Id': record.employeeId,
      'Employee Email': record.employee && record.employee.email,
    }
  })
  exportExcel({
    data,
    title: 'Transaction Discount',
    type: 'xlsx',
  })
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Code"
      source="discountId"
      reference="discounts"
      alwaysOn
      allowEmpty>
      <SelectInput
        optionText="code"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
    <SelectInput
      label="Filter"
      source="date"
      choices={[
        { id: 'all', name: 'All' },
        { id: 'this_month', name: 'This month' },
        { id: 'last_month', name: 'Last month' },
        { id: 'custom', name: 'Choose date' },
      ]}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomDataGrid = withDataProvider(({ dataProvider, ...props }) => {
  const [revenue, setRevenue] = useState(0)

  useEffect(() => {
    const { discountId } = props.filterValues
    dataProvider(GET_ONE, 'transaction-discounts-revenue', {
      id: null,
      discountId,
      date: props.filterValues && props.filterValues.date,
      fromDate: props.filterValues && props.filterValues.fromDate,
      toDate: props.filterValues && props.filterValues.toDate,
    })
      .then(({ data }) => setRevenue(data.result))
      .catch(console.log)
  }, [props.filterValues])

  return (
    <Fragment>
      <h3 style={{ marginLeft: 12 }}>
        Total: {Number(revenue).toLocaleString('vi-VN')}
      </h3>
      <Datagrid {...props}>
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="productName" />
        <TextField source="productType" />
        <FunctionField
          label="Payment type"
          render={record => PAYMENT_TYPE[record.paymentType]}
        />
        <NumberField source="amount" label="Price" />
        <DateField source="createdAt" label="Purchased at" />
        <TextField source="provider" label="Payment method" />
        <CustomTextField
          label="User"
          source="userId"
          reference="users"
          referenceField="id"
          {...props}
        />
        <TextField source="name" label="Name" />
        <TextField source="phone" label="Phone number" />
        <TextField source="email" label="Email" />

        <ReferenceField source="employeeId" reference="users" label="Employee">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </Fragment>
  )
})

const TransactionDiscountList = props => {
  return (
    <List
      {...props}
      exporter={ExportButton}
      filters={<CustomFilter />}
      filterDefaultValues={{
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment()
          .endOf('day')
          .format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <CustomDataGrid />
    </List>
  )
}

export default TransactionDiscountList
