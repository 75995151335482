import React, { useCallback } from 'react'
import {
  Button,
  useRefresh,
  useUpdate,
  useRedirect,
  useNotify,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import { ROLES } from '../constants'

const styles = {
  button: { margin: '5px 0', minWidth: 90 },
  buttonReject: { backgroundColor: 'red' },
}

const ActionButton = props => {
  const {
    classes,
    record,
    action,
    permissions,
    service,
    statusField = 'status',
    ...rest
  } = props

  const refresh = useRefresh()
  const redirect = useRedirect()
  const notify = useNotify()

  const getDataUpdate = () => {
    switch (action) {
      case 'approve':
        return {
          approveRole: permissions,
          ...([
            ROLES.ADMIN,
            ROLES.STATISTICAL_STAFF,
            ROLES.MARKETING_MANAGER,
            ROLES.HEAD_OF_CUSTOMER_SERVICE,
          ].includes(permissions) && {
            [statusField]: 'approved',
          }),
        }
      case 'reject':
        return {
          [statusField]: 'rejected',
        }

      default:
        return {}
    }
  }

  const data = getDataUpdate()

  const [approve] = useUpdate(service, record.id, data, undefined, {
    onSuccess: () => {
      redirect(`/${service}`)
      notify(`Request is ${data.status}`, 'info')
      refresh()
    },
    onFailure: error => {
      notify(`Failure: ${error.message}`, 'warning')
    },
  })

  const handleClick = useCallback(() => {
    approve()
  }, [])

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default withStyles(styles)(ActionButton)
