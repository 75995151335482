import React from 'react'
import { Create } from 'react-admin'
import RoleForm, { transformDataBeforeSubmit } from './RoleForm'

const RoleCreate = props => {
  return (
    <Create {...props} transform={transformDataBeforeSubmit}>
      <RoleForm isCreating permissions={props.permissions} />
    </Create>
  )
}

export default RoleCreate
