import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

import IsFreeField from '../../components/IsFreeField'
import DeleteButton from '../../components/DeleteButton'
import FilterComponent from '../../components/Filter'
import CustomSwitchField from '../../components/CustomSwitchField'
import { ROLES } from '../../components/constants'

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const BookFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </FilterComponent>
)

const BookSummaryList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<BookFilter />}
    actions={<Actions />}
    bulkActionButtons={false}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <IsFreeField resource="book-summaries" label="Is Free" />
      <CustomSwitchField
        resource="book-summaries"
        label="Publish"
        switchField="status"
        disabled={
          ![
            ROLES.ADMIN,
            ROLES.CPSO,
            ROLES.MARKETING_MANAGER,
            ROLES.CONTENT_EDITOR,
          ].includes(permissions)
        }
      />
      <EditButton />
      <DeleteButton permissions={permissions} udoable={false} />
    </Datagrid>
  </List>
)

export default BookSummaryList
