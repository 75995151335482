import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Toolbar, SaveButton, Button, Confirm } from 'react-admin'
import compose from 'recompose/compose'
import IconCancel from '@material-ui/icons/Cancel'

const ChannelToolbar = props => {
  const [isOpen, setOpen] = useState(false)
  const { history, match } = props

  const handleCancel = e => {
    e.preventDefault()
    setOpen(true)
  }

  const handleDelete = () => {
    history.goBack()
  }

  return (
    <Toolbar {...props}>
      <SaveButton
        label={
          match.path.includes('create') ? 'create' : 'update'
        }></SaveButton>
      <Button
        label="ra.action.cancel"
        onClick={handleCancel}
        style={{ marginLeft: 10 }}>
        <IconCancel />
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Are you sure?"
        content="You won't able to revert this"
        onConfirm={handleDelete}
        onClose={() => setOpen(false)}
      />
    </Toolbar>
  )
}

export default compose(withRouter)(ChannelToolbar)
