import React from 'react'
import { Create } from 'react-admin'
import ArticleForm from './ArticleForm'

const ArticleCreate = props => (
  <Create {...props}>
    <ArticleForm />
  </Create>
)

export default ArticleCreate
