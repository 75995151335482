import React from 'react'

import {
  TextInput,
  ImageInput,
  ReferenceArrayInput,
  TabbedForm,
  maxLength,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  FormTab,
  BooleanInput,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  List,
  DeleteButton,
  FunctionField,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CustomImageField from '../../components/CustomImageField'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import ChannelToolbar from '../../components/ChannelToolbar'
import { CustomS3AvatarField } from '../../components/CustomAvatarField'
import { ROLES } from '../../components/constants'
import { makeStyles } from '@material-ui/core/styles'

const styles = {}
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  actionButton: {
    position: 'absolute',
    right: '50px',
    marginTop: '-57px',
  },
}))

const ChannelForm = ({ ...props }) => {
  const { isCreating, record, permissions, ...rest } = props
  const channelId = record.id
  const channelName = record.name
  const classes = useStyles()
  const validateMaxLength = maxLength(
    255,
    'Do not enter more than 255 characters',
  )

  const validateSecondaryCategories = (value, formData) => {
    if (value && value.length > 1) {
      return 'You can only choose one category'
    }

    const { primaryCategoryId } = formData
    if (value && value.includes(primaryCategoryId)) {
      return 'Primary and secondary categories cannot be the same'
    }
    return ''
  }

  return (
    <TabbedForm
      {...rest}
      toolbar={<ChannelToolbar {...props} />}
      redirect="list"
      syncWithLocation={false}>
      <FormTab
        label="Channel Info"
        redirect="list"
        variant="standard"
        margin="normal">
        <GridContainer>
          <Grid item xs={4} className={classes.actionButton}>
            {record && permissions === ROLES.ADMIN && (
              <DeleteButton
                label="Delete"
                record={record}
                confirmTitle={`Delete channel: ${record.name}`}
                mutationMode="pessimistic"
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              label="Podcaster"
              source="podcasterId"
              reference="podcasters"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
              validate={validateRequired('Podcaster')}>
              <AutocompleteInput
                optionText="name"
                fullWidth
                required
                variant="standard"
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={8}>
            <TextInput
              source="rssUrl"
              fullWidth
              variant="standard"
              validate={[validateRequired('RSS Url'), validateMaxLength]}
              disabled={!isCreating}
            />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              label="Primary category"
              source="primaryCategoryId"
              reference="categories"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'podcast',
                primary: true,
              })}
              perPage={50}
              validate={validateRequired('Primary category')}>
              <AutocompleteInput
                optionText="name"
                fullWidth
                required
                variant="standard"
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={8}>
            <ReferenceArrayInput
              label="Secondary categories"
              source="secondaryCategoryIds"
              reference="categories"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'podcast',
                primary: true,
              })}
              perPage={50}
              validate={validateSecondaryCategories}>
              <AutocompleteArrayInput
                optionText="name"
                fullWidth
                required
                variant="standard"
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <FunctionField
              source="category"
              label="Raw category"
              render={record => {
                if (record.category) {
                  return `Raw category: ${record.category}`
                }
                return null
              }}
            />
          </Grid>
          {!isCreating && channelName && (
            <>
              <Grid item xs={12}>
                <ImageInput
                  source="coverImageUrl"
                  label="Cover image"
                  accept="image/*"
                  multiple={false}>
                  <CustomImageField source="src" title="Cover image" />
                </ImageInput>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  source="name"
                  label="Channel name"
                  resettable
                  fullWidth
                  variant="standard"
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="description"
                  resettable
                  minRows={10}
                  fullWidth
                  variant="standard"
                  multiline
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <BooleanInput
              label="Auto-approve new episodes from RSS feed?"
              source="autoApproval"
              defaultValue={true}
              defaultChecked={true}
              disabled={true}
            />
          </Grid>
          {!isCreating && !channelName && (
            <Grid item xs={12}>
              <p>
                Dữ liệu đang được tải. Vui lòng quay trở lại sau khoảng 10 phút
                nữa...
              </p>
            </Grid>
          )}
        </GridContainer>
      </FormTab>
      {!isCreating && (
        <FormTab {...rest} label="Episodes" variant="filled" margin="normal">
          <List
            fullWidth
            resource="episodes"
            basePath="/episodes"
            sort={{ field: 'publishDate', order: 'DESC' }}
            bulkActionButtons={false}
            exporter={false}
            filterDefaultValues={{
              channelId: channelId,
            }}>
            <Datagrid>
              <TextField source="episodeIndex" label="Order" />
              <TextField source="title" label="Title" />
              <CustomS3AvatarField source="coverImageUrl" label="Cover Image" />
              <DateField source="publishDate" label="Published date" />
              <TextField source="status" label="Status" />
              <EditButton basePath="/episodes" />
            </Datagrid>
          </List>
        </FormTab>
      )}
    </TabbedForm>
  )
}

export default withStyles(styles)(ChannelForm)
