import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  ReferenceInput,
  TextInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

import ListStatusField from '../../components/ListStatusField'
import IsFreeField from '../../components/IsFreeField'
import DeleteButton from '../../components/DeleteButton'
import FilterComponent from '../../components/Filter'
import moment from 'moment/moment'
import { exportExcel } from '../../utils/exportExcel'

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      Title: record.title,
      Description: record.description,
      'Date published':
        record.datePublished &&
        moment(record.datePublished).format('DD/MM/YYYY'),
      'Created by': record.createdBy,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'Updated by': record.updatedBy,
      'Updated at':
        record.updatedAt && moment(record.updatedAt).format('DD/MM/YYYY'),
      AuthorIds: record.authorIds && record.authorIds.join(', '),
      Categories:
        record.categories && record.categories.map(c => c.name).join(', '),
    }
  })

  exportExcel({
    data,
    title: `Ebooks_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const BookFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
    <ReferenceInput
      label="Author"
      source="authorId"
      reference="authors"
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
  </FilterComponent>
)

const EbookList = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<BookFilter />}
    actions={<Actions />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    exporter={exporter}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <IsFreeField resource="ebooks" label="Is Free" />
      <ListStatusField resource="ebooks" label="Publish" />
      <EditButton />
      <DeleteButton permissions={permissions} undoable={false} />
    </Datagrid>
  </List>
)

export default EbookList
