import React from 'react'
import { Create } from 'react-admin'

import MeditationForm from './MeditationForm'

const MeditationCreate = props => (
  <Create {...props}>
    <MeditationForm permissions={props.permissions} />
  </Create>
)

export default MeditationCreate
