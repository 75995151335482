import React from 'react'
import { Create } from 'react-admin'

import AffiliatePaymentsForm from './AffiliatePaymentsForm'

const AffiliatePaymentsCreate = props => (
  <Create {...props}>
    <AffiliatePaymentsForm />
  </Create>
)

export default AffiliatePaymentsCreate
