import React from 'react'
import { Create } from 'react-admin'

import EbookForm from './EbookForm'

const EbookCreate = props => (
  <Create {...props}>
    <EbookForm permissions={props.permissions} />
  </Create>
)

export default EbookCreate
