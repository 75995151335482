import React from 'react'
import { Filter } from 'react-admin'

const FilterComponent = ({ styles, ...props }) => {
  return (
    <div style={{ marginBottom: 10, ...styles }}>
      <Filter {...props} variant="standard">
        {props.children}
      </Filter>
    </div>
  )
}

export default FilterComponent
