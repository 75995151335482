import React from 'react'

import { TextInput, FileInput, SimpleForm } from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import CustomFileField from '../../components/CustomFileField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}
const MeditationBackgroundForm = ({ permissions, ...props }) => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Name')]}
          />
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File meditation background"
            accept="audio/*"
            multiple={false}
            validate={validateRequired('File Meditation')}>
            <CustomFileField
              source="src"
              title="Meditation background"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(MeditationBackgroundForm)
