import React from 'react'
import get from 'lodash/get'

import { withStyles } from '@material-ui/core'

const styles = {
  root: { display: 'inline-block' },
}

const CustomFileField = props => {
  const {
    classes,
    className,
    record,
    source,
    title,
    target,
    canDownload,
  } = props
  const sourceValue = get(record, source)
  const titleValue = get(record, title) || title

  if (record && !sourceValue) {
    return (
      <div className={`${classes.root} ${className}`}>
        {canDownload ? (
          <a
            href={`${process.env.REACT_APP_S3_URL}/${record}`}
            title={titleValue}
            target={target}>
            {titleValue}
          </a>
        ) : (
          <span>{titleValue}</span>
        )}
      </div>
    )
  }

  if (!sourceValue) {
    return <div className={`${classes.root} ${className}`} />
  }

  return (
    <div className={`${classes.root} ${className}`}>
      {canDownload ? (
        <a href={sourceValue} title={titleValue} target={target}>
          {titleValue}
        </a>
      ) : (
        <span>{titleValue}</span>
      )}
    </div>
  )
}

export default withStyles(styles)(CustomFileField)
