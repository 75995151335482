import React from 'react'
import { Edit } from 'react-admin'
import AuthorForm from './AuthorForm'

const AuthorEdit = props => (
  <Edit undoable={false} {...props}>
    <AuthorForm />
  </Edit>
)

export default AuthorEdit
