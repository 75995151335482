import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  SelectInput,
  DateInput,
} from 'react-admin'
import UserDetailField from '../../components/UserDetailField'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import FilterComponent from '../../components/Filter'

const CsDeleteAccountFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Filter"
      source="date"
      choices={[
        { id: 'all', name: 'All' },
        { id: 'this_month', name: 'This month' },
        { id: 'last_month', name: 'Last month' },
        { id: 'custom', name: 'Choose date' },
      ]}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CsDeleteAccountList = props => {
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<CsDeleteAccountFilter />}
        bulkActionButtons={false}>
        <Datagrid>
          <UserDetailField label="User" />
          <TextField source="reason" />
          <ReferenceField
            label="Created by"
            source="createdBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Approved by"
            source="approvedBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ApproveStatus label="Status" />
          <ApproveButtons
            permissions={props.permissions}
            service="cs-delete-accounts"
          />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CsDeleteAccountList
