import React from 'react'
import { Create } from 'react-admin'
import AudioSeriesForm from './AudioSeriesForm'

const AudioSeriesCreate = props => (
  <Create title={'Create Audio-series'} {...props}>
    <AudioSeriesForm />
  </Create>
)

export default AudioSeriesCreate
