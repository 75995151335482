import React from 'react'
import moment from 'moment'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Filter,
  TextInput,
  ReferenceField,
  DateInput,
  SelectInput,
} from 'react-admin'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import { ROLES } from '../../components/constants'
import CustomSwitchField from '../../components/CustomSwitchField'
import { exportExcel } from '../../utils/exportExcel'

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      Name: record.name,
      Email: record.email,
      Status: record.status,
      Slug: record.slug,
      CollectionId: record.collectionId && record.collection_code.id,
      Collection: record.collection_code && record.collection_code.name,
      'Created by': record.createdUser && record.createdUser.name,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'Updated by': record.updatedUser && record.updatedUser.name,
      'Updated at':
        record.updatedAt && moment(record.updatedAt).format('DD/MM/YYYY'),
      'Start at': record.startAt && moment(record.startAt).format('DD/MM/YYYY'),
      'Expire at':
        record.expireAt && moment(record.expireAt).format('DD/MM/YYYY'),
    }
  })

  exportExcel({
    data,
    title: `Affiliates_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const AffiliateCollectionFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn />
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === props.filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === props.filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </Filter>
  )
}

const AffiliateCollectionList = props => {
  return (
    <List
      {...props}
      filters={<AffiliateCollectionFilter />}
      exporter={exporter}
      sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="totalPurchased" />
        <TextField source="totalRedeemed" />
        <DateField label="Start at" source="startAt" />
        <DateField label="Expire at" source="expireAt" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <CustomSwitchField
          resource="affiliates"
          label="Status"
          switchField="status"
          disabled={
            ![
              ROLES.ADMIN,
              ROLES.CPSO,
              ROLES.MARKETING_MANAGER,
              ROLES.STATISTICAL_STAFF,
            ].includes(props.permissions)
          }
        />
        <ApproveStatus label="Status" statusField="approveStatus" />
        <ApproveButtons
          permissions={props.permissions}
          service="affiliate-collections"
          statusField="approveStatus"
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default AffiliateCollectionList
