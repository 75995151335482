import React from 'react'
import { Create } from 'react-admin'

import BannerForm from './BannerForm'

const BannerCreate = props => (
  <Create {...props}>
    <BannerForm />
  </Create>
)

export default BannerCreate
