import React from 'react'
import { Create } from 'react-admin'
import BundleForm from './BundleForm'

const BundleCreate = props => (
  <Create {...props}>
    <BundleForm />
  </Create>
)

export default BundleCreate
