import React, { useState } from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { COURSE_STATUSES } from './constants'
import get from 'lodash/get'

const CourseStatusField = props => {
  const { record, dataProvider, disabled } = props
  const [status, setStatus] = useState(record.status)

  const handleChange = async () => {
    const data = {
      status:
        status === COURSE_STATUSES.PUBLISHED
          ? COURSE_STATUSES.UNPUBLISHED
          : COURSE_STATUSES.PUBLISHED,
    }

    const response = await dataProvider(
      UPDATE,
      'courses',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Course updated', level: 'info' },
          redirectTo: '/courses',
        },
        onFailure: {
          notification: {
            body: 'Error: courses not updated',
            level: 'warning',
          },
        },
      },
    )
    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
    }
  }

  return [COURSE_STATUSES.PUBLISHED, COURSE_STATUSES.UNPUBLISHED].includes(
    record.status,
  ) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === COURSE_STATUSES.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default withDataProvider(CourseStatusField)
