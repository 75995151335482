import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  SelectInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  ExportButton,
  CreateButton,
  TopToolbar,
} from 'react-admin'
import CustomTextField from '../../components/CustomTextField'
import { exportExcel } from '../../utils/exportExcel'
import {
  CODE_TYPES,
  CODE_TYPE_NAME,
  FILTER_DATE,
} from '../../components/constants'
import FilterComponent from '../../components/Filter'
import DeleteButton from '../../components/DeleteButton'
import moment from 'moment'
import ListStatusField from '../../components/ListStatusField'

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const getCodePrice = record => {
  switch (record.type) {
    case 'book':
      return record.book && record.book.price

    case 'one_month_subscription':
      return 99000

    case 'one_year_subscription':
      return 899000

    case 'three_month_subscription':
      return 249000

    case 'three_year_subscription':
      return 1799000

    case 'collection':
      return record.collection_code && record.collection_code.price
  }
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      Code: record.code,
      Type: record.type,
      Book: record.book && record.book.title,
      BookId: record.bookId,
      Price: getCodePrice(record),
      BatchId: record.batch_code && record.batch_code.batchId,
      Source: record.source,
      'Created by': record.createdUser && record.createdUser.name,
      'Payment method': record.paymentMethod,
      'Recipient email': record.recipientEmail || record.invoiceEmail,
      'Phone number': record.payment_order && record.payment_order.phone,
      'Invoice email': record.invoiceEmail,
      'Redeemed at':
        record.redeemedAt && moment(record.redeemedAt).format('DD/MM/YYYY'),

      'Redeemed user': record.redeemedUser && record.redeemedUser.username,
      'Redeemed user id': record.userId,
    }
  })

  exportExcel({
    data,
    title: 'API codes',
    type: 'xlsx',
  })
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Code" source="code" alwaysOn />
    <TextInput label="Email" source="invoiceEmail" alwaysOn />
    <ReferenceInput
      label="Batch ID"
      source="batchId"
      reference="batch-codes"
      alwaysOn
      filterToQuery={searchText => ({
        batchId: searchText,
      })}>
      <AutocompleteInput optionText="batchId" />
    </ReferenceInput>
    <SelectInput
      source="type"
      choices={CODE_TYPES}
      alwaysOn
      allowEmpty={true}
    />
    <SelectInput
      source="source"
      choices={[{ id: 'web', name: 'Website' }, { id: 'tiki', name: 'Tiki' }]}
      alwaysOn
      allowEmpty={true}
    />
    <SelectInput
      label="Generated Date"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const ApiCodeList = props => (
  <List
    {...props}
    filters={<CustomFilter />}
    exporter={exporter}
    actions={<Actions />}
    sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="code" label="Code" />
      <DateField source="createdAt" />
      <FunctionField
        source="type"
        render={record => CODE_TYPE_NAME[record.type]}
      />

      <TextField label="Created By" source="createdUser.name" />

      <CustomTextField
        label="Book"
        source="bookId"
        reference="books"
        referenceField="title"
        {...props}
      />
      <CustomTextField
        label="Batch ID"
        source="batchCodeId"
        reference="batch-codes"
        referenceField="batchId"
        {...props}
      />
      <TextField source="source" label="Source" />
      <TextField source="paymentMethod" label="Payment method" />
      <FunctionField
        label="Recipient email"
        render={record => record.recipientEmail || record.invoiceEmail}
      />
      <FunctionField
        label="Phone number"
        render={record => {
          if (record.payment_order && record.payment_order.phone) {
            return record.payment_order.phone
          }
          return null
        }}
      />
      <FunctionField
        label="Copy email"
        render={record => {
          if (record.recipientEmail) {
            return record.invoiceEmail
          }
          return null
        }}
      />
      <DateField source="redeemedAt" />
      <CustomTextField
        label="Redeemed user"
        source="userId"
        reference="users"
        referenceField="username"
        {...props}
      />
      <FunctionField
        label="Status"
        render={record => {
          if (record.userId || record.redeemAt) return null
          return (
            <ListStatusField
              resource="codes"
              label="Status"
              record={record}
              {...props}
            />
          )
        }}
      />
      <DeleteButton permissions={props.permissions} />
    </Datagrid>
  </List>
)

export default ApiCodeList
