import React from 'react'

const UserDetailField = ({ record, idField = 'userId' }) => {
  const userId = record[idField]

  return (
    <a style={{ color: '#0000EE' }} href={`#/user-details/${userId}/show`}>
      {userId}
    </a>
  )
}

export default UserDetailField
