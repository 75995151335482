import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core'

const styles = {
  container: {
    width: 'unset',
  },
}
const GridContainer = props => {
  return (
    <Grid container spacing={2} className={props.classes.container}>
      {props.children}
    </Grid>
  )
}

export default withStyles(styles)(GridContainer)
