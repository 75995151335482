import React from 'react'
import { Route } from 'react-router-dom'
import ChangePasswordPage from './components/ChangePasswordPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import ResetPasswordPage from './components/ResetPasswordPage'

export default [
  <Route
    key={'changePassword'}
    exact
    path="/changePassword"
    component={ChangePasswordPage}
  />,
  <Route
    key={'forgot'}
    exact
    path="/forgot"
    component={ForgotPasswordPage}
    noLayout
  />,
  <Route
    key={'reset'}
    exact
    path="/reset"
    component={ResetPasswordPage}
    noLayout
  />,
]
