import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Title,
  TextInput,
  SimpleForm,
  CREATE,
  showNotification,
} from 'react-admin'
import { validateRequired, match } from '../utils/validate'
import getDataProvider from '../dataProvider'
import { connect } from 'react-redux'

const ChangePasswordPage = props => {
  const handleSubmit = data => {
    const { showNotification } = props
    return (
      getDataProvider()(CREATE, 'users/changePassword', {
        data: {
          oldPassword: data.current_password,
          password: data.new_password,
        },
      })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          showNotification('Change password successfully.')
        })
        .catch(error => {
          showNotification(error.message)
        })
    )
  }

  return (
    <Card>
      <Title title="Change Password" />
      <CardContent>
        <SimpleForm save={handleSubmit}>
          <TextInput
            label="Current password"
            source="current_password"
            type="password"
            variant="standard"
            validate={validateRequired('Current Password')}
          />
          <TextInput
            label="New password"
            source="new_password"
            type="password"
            variant="standard"
            validate={validateRequired('New Password')}
          />
          <TextInput
            label="Confirm password"
            source="confirm_password"
            type="password"
            variant="standard"
            validate={[
              validateRequired('Confirm Password'),
              match('new_password', 'New password'),
            ]}
          />
        </SimpleForm>
      </CardContent>
    </Card>
  )
}

export default connect(
  null,
  { showNotification },
)(ChangePasswordPage)
