import React from 'react'
import { Create } from 'react-admin'

import ConfigurationSettingForm from './ConfigurationSettingForm'

const ConfigurationSettingCreate = props => (
  <Create {...props}>
    <ConfigurationSettingForm isCreating permissions={props.permissions} />
  </Create>
)

export default ConfigurationSettingCreate
