import React from 'react'
import { Create } from 'react-admin'

import VoiceActorForm from './VoiceActorForm'

const VoiceActorCreate = props => (
  <Create {...props}>
    <VoiceActorForm />
  </Create>
)

export default VoiceActorCreate
