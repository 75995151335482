import React from 'react'
import { Edit } from 'react-admin'

import CommissionForm from './CommissionForm'

const CommissionEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CommissionForm permissions={props.permissions} />
    </Edit>
  )
}

export default CommissionEdit
