import React, { Fragment, useMemo } from 'react'

import {
  TextInput,
  NumberInput,
  SimpleForm,
  SelectInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { useField, useForm } from 'react-final-form'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const effectiveScopeOptions = [
  { id: 'publisher', name: 'Publisher' },
  { id: 'book', name: 'Book' },
]
const durationTypeOptions = [
  { id: 'permanent', name: 'Permanent' },
  { id: 'period', name: 'Period' },
]

const EffectiveScopeSection = () => {
  const form = useForm()
  const effectiveScopeField = useField('effectiveScope', form.getState())
  const effectiveScope = effectiveScopeField.input.value

  const scopeReferenceInput = useMemo(() => {
    switch (effectiveScope) {
      case 'publisher': {
        return (
          <ReferenceInput
            label="Publisher"
            source="publisherId"
            reference="users"
            filter={{ role: 'publisher' }}
            alwaysOn
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
            })}
            allowEmpty>
            <AutocompleteInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
            />
          </ReferenceInput>
        )
      }
      case 'book': {
        return (
          <ReferenceInput
            label="Book"
            source="bookId"
            reference="books"
            filterToQuery={searchText => ({
              title: { $iLike: `%${searchText}%` },
            })}
            alwaysOn
            allowEmpty>
            <AutocompleteInput
              optionText="title"
              options={{
                fullWidth: true,
                variant: 'standard',
              }}
            />
          </ReferenceInput>
        )
      }
      default:
        return null
    }
  }, [effectiveScope])

  return (
    <Fragment>
      <Grid container item xs={12}>
        <SelectInput
          label="Effective scope"
          source="effectiveScope"
          choices={effectiveScopeOptions}
          alwaysOn
          fullWidth
          allowEmpty={false}
        />
      </Grid>

      <Grid container item xs={12}>
        {scopeReferenceInput}
      </Grid>
    </Fragment>
  )
}

const DurationTypeSection = () => {
  const form = useForm()
  const durationTypeField = useField('durationType', form.getState())
  const durationType = durationTypeField.input.value

  return (
    <Fragment>
      <Grid container item xs={12}>
        <SelectInput
          label="Duration type"
          source="durationType"
          choices={durationTypeOptions}
          alwaysOn
          fullWidth
          allowEmpty={false}
        />
      </Grid>
      {durationType && durationType === 'period' && (
        <>
          <Grid item xs={6}>
            <DateInput
              label="Start date"
              source="startDate"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label="End date"
              source="endDate"
              fullWidth
              variant="standard"
            />
          </Grid>
        </>
      )}
    </Fragment>
  )
}

const CommissionForm = ({ ...props }) => {
  const { isCreating } = props
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        {!isCreating && (
          <Grid container item xs={4}>
            <TextInput
              disabled
              fullWidth
              source="id"
              variant="standard"></TextInput>
          </Grid>
        )}

        <EffectiveScopeSection />
        <DurationTypeSection />

        <Grid item xs={12}>
          <NumberInput
            label="Commission"
            source="commission"
            fullWidth
            variant="standard"
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(CommissionForm)
