import React from 'react'

import {
  TextInput,
  maxLength,
  SimpleForm,
  SelectInput,
  ImageInput,
} from 'react-admin'

import Grid from '@material-ui/core/Grid'

import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const isShow = [{ id: true, name: 'Show' }, { id: false, name: 'Hide' }]

const AudioSeriesForm = props => {
  const validateMaxLength = maxLength(
    50,
    'Do not enter more than 50 characters',
  )

  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={8}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type'), validateMaxLength]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            source="status"
            choices={isShow}
            fullWidth
            variant="standard"
            required
            defaultValue={true}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="headerImageUrl"
            label="Header image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Header Image')}>
            <CustomImageField source="src" title="Header image" />
          </ImageInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default AudioSeriesForm
