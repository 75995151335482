import React from 'react'
import { Edit } from 'react-admin'
import RoleForm, { transformDataBeforeSubmit } from './RoleForm'

const RoleEdit = props => (
  <Edit undoable={false} {...props} transform={transformDataBeforeSubmit}>
    <RoleForm permissions={props.permissions} />
  </Edit>
)

export default RoleEdit
