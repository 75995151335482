import React from 'react'

import {
  TextInput,
  SelectInput,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  maxValue,
  minValue,
  SimpleForm,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import { validateRequired, validateUnique } from '../../utils/validate'
import CustomFileInput from '../../components/CustomFileInput'
import GridContainer from '../../components/GridContainer'
import { ROLES } from '../../components/constants'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const validateDescription = [validateRequired('Description')]

const validateChapterOrder = [
  validateRequired('Order'),
  minValue(1),
  maxValue(1000),
]
const isFree = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }]
const statuses = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }]

const GuidedMeditationForm = props => {
  const { permissions, ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list" variant="standard" margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            validate={validateRequired('Title')}
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free')}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Publish"
            source="status"
            choices={statuses}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Publish')}
          />
        </Grid>

        <Grid item xs={4}>
          <ReferenceInput
            label="Primary category"
            source="primaryCategoryId"
            reference="categories"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: 'meditation',
            })}
            validate={validateRequired('Primary category')}>
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={8}>
          <ReferenceArrayInput
            label="Secondary categories"
            source="secondaryCategoryIds"
            reference="categories"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: 'meditation',
            })}>
            <AutocompleteArrayInput
              optionText="name"
              fullWidth
              required
              variant="standard"
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={4}>
          <TextInput
            source="duration"
            fullWidth
            validate={validateRequired('Duration')}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            validate={validateDescription}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>
      </GridContainer>

      <ArrayInput
        label="Sessions"
        source="sessions"
        fullWidth
        validate={[
          validateRequired('Sessions'),
          validateUnique('order', true),
        ]}>
        <SimpleFormIterator>
          <NumberInput
            source="order"
            label="Order"
            variant="standard"
            validate={validateChapterOrder}
          />
          <TextInput
            source="title"
            label="Name"
            fullWidth
            variant="standard"
            validate={validateRequired('name')}
          />

          <CustomFileInput
            source="maleVoiceUrl"
            label="Male voice"
            type="meditation-session"
            upload
            multiple={false}>
            <CustomFileField
              source="src"
              title="Male voice"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>

          <CustomFileInput
            source="femaleVoiceUrl"
            label="Female voice"
            type="meditation-session"
            upload
            multiple={false}>
            <CustomFileField
              source="src"
              title="Female voice"
              canDownload={permissions === ROLES.ADMIN}
            />
          </CustomFileInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}

export default withStyles(styles)(GuidedMeditationForm)
