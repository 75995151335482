import React, { useRef, useState } from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import get from 'lodash/get'

const styles = createStyles({
  textEllipsis: {
    'word-wrap': 'break-word',
    overflow: 'hidden',
    ' text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
})

const TextFieldTruncate = ({
  record,
  source,
  classes,
  maxLine = 4,
  ...props
}) => {
  const [toggle, setToggle] = useState(false)
  const contentRef = useRef(null)
  const text = get(record, source, '')

  return (
    <div onClick={() => setToggle(prev => !prev)}>
      <span
        style={{ WebkitLineClamp: toggle ? 'unset' : maxLine.toString() }}
        className={classes.textEllipsis}
        ref={contentRef}
        {...props}>
        {text}
      </span>
    </div>
  )
}

export default withStyles(styles)(TextFieldTruncate)
