import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
} from 'react-admin'

const MarketingCampaignList = props => (
  <List {...props} perPage={1000} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startDate" />
      <DateField source="endDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default MarketingCampaignList
