import React from 'react'
import { Create } from 'react-admin'

import GuidedMeditationForm from './GuidedMeditationForm'

const GuidedMeditationCreate = props => (
  <Create {...props}>
    <GuidedMeditationForm isCreating permissions={props.permissions} />
  </Create>
)

export default GuidedMeditationCreate
