import React from 'react'
import { Edit } from 'react-admin'
import CoachForm from './CoachForm'

const CoachEdit = props => (
  <Edit undoable={false} {...props}>
    <CoachForm />
  </Edit>
)

export default CoachEdit
