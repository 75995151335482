import isEmpty from 'lodash/isEmpty'
const requiredArray = field => value => {
  if (isEmpty(value)) return `Missing required ${field} field`
  return ''
}

const validateRequired = field => value => {
  if (
    value === undefined ||
    (typeof value === 'object' && isEmpty(value)) ||
    (typeof value === 'string' && value.trim() === '')
  )
    return `Missing required ${field} field`
  return ''
}

const validateUnique = (uniqueField, isNumber = false) => value => {
  if (!value) return

  const val = value
    .filter(i => !!i)
    .map(function(row) {
      return !isNumber ? row[uniqueField] : parseInt(row[uniqueField])
    })

  const duplicate = val.filter((item, index) => {
    return val.indexOf(item) !== index
  })

  return duplicate.length > 0
    ? `Value "${duplicate.join(
        '", "',
      )}" for ${uniqueField.toUpperCase()} field is duplicated`
    : ''
}

const match = (matchName, fieldName) => (value, allValues) => {
  return value !== allValues[matchName]
    ? `This field must match with ${fieldName || matchName} field`
    : undefined
}

export { requiredArray, validateRequired, validateUnique, match }
