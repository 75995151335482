import React from 'react'
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  TextField,
  ReferenceField,
  FunctionField,
  SelectInput,
} from 'react-admin'
import {
  CONFIGURATION_SETTING_DATA_TYPE_LABEL,
  CONFIGURATION_SETTING_USE_SCOPE,
  CONFIGURATION_SETTING_USE_SCOPE_LABEL,
} from './constants'
import FilterComponent from '../../components/Filter'

const CustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Use Scope"
      source="useScope"
      choices={[
        {
          id: CONFIGURATION_SETTING_USE_SCOPE.HOMEPAGE_CONFIGURATIONS,
          name:
            CONFIGURATION_SETTING_USE_SCOPE_LABEL[
              CONFIGURATION_SETTING_USE_SCOPE.HOMEPAGE_CONFIGURATIONS
            ],
        },
        {
          id: CONFIGURATION_SETTING_USE_SCOPE.PODCAST_TAB_CONFIGURATIONS,
          name:
            CONFIGURATION_SETTING_USE_SCOPE_LABEL[
              CONFIGURATION_SETTING_USE_SCOPE.PODCAST_TAB_CONFIGURATIONS
            ],
        },
        {
          id: CONFIGURATION_SETTING_USE_SCOPE.ENGLISH_BOOK_TAB_CONFIGURATIONS,
          name:
            CONFIGURATION_SETTING_USE_SCOPE_LABEL[
              CONFIGURATION_SETTING_USE_SCOPE.ENGLISH_BOOK_TAB_CONFIGURATIONS
            ],
        },
        {
          id: CONFIGURATION_SETTING_USE_SCOPE.COURSE_TAB_CONFIGURATIONS,
          name:
            CONFIGURATION_SETTING_USE_SCOPE_LABEL[
              CONFIGURATION_SETTING_USE_SCOPE.COURSE_TAB_CONFIGURATIONS
            ],
        },
      ]}
      alwaysOn
    />
  </FilterComponent>
)

const ConfigurationSettingList = ({ ...props }) => {
  return (
    <List {...props} filters={<CustomFilter />} bulkActionButtons={false}>
      <Datagrid>
        <TextField label="Id" source="id" />
        <FunctionField
          label="Data Type"
          source="dataType"
          render={record =>
            CONFIGURATION_SETTING_DATA_TYPE_LABEL[record.dataType]
          }
        />
        <TextField label="Multiple Value" source="multiple" />
        <FunctionField
          label="Use Scope"
          source="useScope"
          render={record =>
            CONFIGURATION_SETTING_USE_SCOPE_LABEL[record.useScope]
          }
        />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ConfigurationSettingList
