import React from 'react'
import { Create } from 'react-admin'

import MeditationBackgroundForm from './MeditationBackgroundForm'

const MeditationBackgroundCreate = props => (
  <Create {...props}>
    <MeditationBackgroundForm permissions={props.permissions} />
  </Create>
)

export default MeditationBackgroundCreate
