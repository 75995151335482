import React from 'react'

import { withStyles } from '@material-ui/core'

const styles = {
  imageStyle: {
    maxHeight: '10rem',
    margin: 8,
  },
}

const CustomImageField = props => {
  const { classes, className, record, source, originalSrc = false } = props

  return (
    <img
      id="coverImage"
      alt={record[source] || record}
      src={
        record[source] ||
        (originalSrc ? record : `${process.env.REACT_APP_S3_URL}/${record}`)
      }
      className={`${classes.imageStyle} ${className}`}></img>
  )
}

export const CustomS3ImageField = props => {
  const { record, source } = props
  return (
    <img
      alt={record[source]}
      src={`${process.env.REACT_APP_S3_URL}/${record[source]}`}
      style={{ maxHeight: '10rem' }}></img>
  )
}

export default withStyles(styles)(CustomImageField)
