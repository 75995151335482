import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import DateTimeField from '../../components/DateTimeField'
import ReactDiffViewer from 'react-diff-viewer'
import _ from 'lodash'
import FilterComponent from '../../components/Filter'

const ActivityFilter = props => {
  return (
    <FilterComponent {...props}>
      <SelectInput
        source="targetObj"
        label="Type"
        choices={[
          { id: 'users', name: 'Account' },
          { id: 'roles', name: 'Role' },
          { id: 'reviews', name: 'Review' },
          { id: 'codes', name: 'Code' },
          { id: 'batch-codes', name: 'Batch Code' },
        ]}
        alwaysOn
        allowEmpty={true}></SelectInput>
      <TextInput source="name" label="Name" alwaysOn />
    </FilterComponent>
  )
}

const ActivityList = props => {
  return (
    <List
      {...props}
      filters={<ActivityFilter permissions={props.permissions} />}
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid>
        <DateTimeField source="createdAt" label="Time" />
        <ReferenceField label="Action by" source="actionBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <FunctionField
          label="Action"
          render={record => {
            switch (record.action) {
              case 'create':
                return 'Create'
              case 'update':
              case 'patch':
                return 'Edit'
              case 'remove':
                return 'Delete'
              default:
                return 'N/A'
            }
          }}
        />
        <FunctionField
          label="Type"
          render={record => {
            switch (record.targetObj) {
              case 'roles':
                return 'Role'
              case 'users':
                return 'Account'
              case 'reviews':
                return 'Review'
              case 'codes':
                return 'Code'
              case 'batch-codes':
                return 'Batch Code'
              default:
                return 'N/A'
            }
          }}
        />
        <FunctionField
          label="Data"
          render={record => {
            switch (record.targetObj) {
              case 'roles':
                return (
                  <>
                    <span>Name: {record.newData && record.newData.name}</span>
                    {/*<br />*/}
                    {/*<span>Code: {record.objData && record.objData.id}</span>*/}
                  </>
                )
              case 'users':
                return (
                  <>
                    <span>Email: {record.newData && record.newData.email}</span>
                    <br />
                    <span>Role: {record.newData && record.newData.role}</span>
                  </>
                )
              case 'reviews':
                return (
                  <>
                    <span>
                      Review: {record.newData && record.newData.review}
                    </span>
                    <br />
                    <span>Voice: {record.newData && record.newData.voice}</span>
                    <br />
                    <span>
                      Rating: {record.newData && record.newData.rating}
                    </span>
                  </>
                )
              case 'codes':
                return (
                  <>
                    <span>Code: {record.newData && record.newData.code}</span>
                    <br />
                    <span>Type: {record.newData && record.newData.type}</span>
                    <br />
                    <span>
                      Book ID: {record.newData && record.newData.bookId}
                    </span>
                    <br />
                    <span>
                      Status: {record.newData && record.newData.status}
                    </span>
                  </>
                )
              case 'batch-codes':
                return (
                  <>
                    <span>
                      Batch Id: {record.newData && record.newData.batchId}
                    </span>
                    <br />
                    <span>Type: {record.newData && record.newData.type}</span>
                    <br />
                    <span>
                      Quantity: {record.newData && record.newData.quantity}
                    </span>
                    <br />
                    <span>
                      Status: {record.newData && record.newData.status}
                    </span>
                  </>
                )
              default:
                return 'N/A'
            }
          }}
        />
        <FunctionField
          label="Changed"
          render={record => {
            if (record.action === 'update' || record.action === 'patch') {
              const renderValues = (record, fields, isNew) => {
                return fields
                  .map(field => {
                    const oldVal = _.get(record, `oldData.${field}`)
                    const newVal = _.get(record, `newData.${field}`)
                    const isSame = oldVal === newVal

                    return renderValue(isSame, isNew ? newVal : oldVal, field)
                  })
                  .filter(Boolean)
                  .join('\n')
              }

              const renderValue = (isSame, value, prefix) => {
                return isSame ? `` : `${prefix}: ${value}`
              }

              switch (record.targetObj) {
                case 'roles': {
                  let originOldPermission =
                    _.get(record, 'oldData.permission.can.manage') || []

                  let newPermission =
                    _.get(record, 'newData.permission.can.manage') || []

                  let oldPermission = originOldPermission.filter(
                    p => !newPermission.includes(p),
                  )
                  newPermission = newPermission.filter(
                    p => !originOldPermission.includes(p),
                  )

                  const isSamePermission =
                    JSON.stringify(oldPermission) ===
                    JSON.stringify(newPermission)

                  const oldName = _.get(record, 'oldData.name')
                  const newName = _.get(record, 'newData.name')
                  const isSameName = oldName === newName

                  return (
                    <ReactDiffViewer
                      oldValue={[
                        renderValue(
                          isSamePermission,
                          oldPermission.map(p => `\n - ${p}`).join(''),
                          'permission',
                        ),
                        renderValue(isSameName, oldName, 'name'),
                      ]
                        .filter(Boolean)
                        .join('\n')}
                      newValue={[
                        renderValue(
                          isSamePermission,
                          newPermission.map(p => `\n - ${p}`).join(''),
                          'permission',
                        ),
                        renderValue(isSameName, newName, 'name'),
                      ]
                        .filter(Boolean)
                        .join('\n')}
                      splitView={true}
                    />
                  )
                }
                case 'users': {
                  return (
                    <ReactDiffViewer
                      oldValue={renderValues(
                        record,
                        ['name', 'email', 'role'],
                        false,
                      )}
                      newValue={renderValues(
                        record,
                        ['name', 'email', 'role'],
                        true,
                      )}
                      splitView={true}
                    />
                  )
                }

                case 'reviews': {
                  return (
                    <ReactDiffViewer
                      oldValue={renderValues(record, ['status'], false)}
                      newValue={renderValues(record, ['status'], true)}
                      splitView={true}
                    />
                  )
                }

                case 'codes': {
                  return (
                    <ReactDiffViewer
                      oldValue={renderValues(record, ['status'], false)}
                      newValue={renderValues(record, ['status'], true)}
                      splitView={true}
                    />
                  )
                }

                case 'batch-codes': {
                  return (
                    <ReactDiffViewer
                      oldValue={renderValues(
                        record,
                        [
                          'status',
                          'type',
                          'period',
                          'batchId',
                          'quantity',
                          'expiredAt',
                          'promotionPercentage',
                        ],
                        false,
                      )}
                      newValue={renderValues(
                        record,
                        [
                          'status',
                          'type',
                          'period',
                          'batchId',
                          'quantity',
                          'expiredAt',
                          'promotionPercentage',
                        ],
                        true,
                      )}
                      splitView={true}
                    />
                  )
                }

                default:
                  return 'N/A'
              }
            }
            return null
          }}
        />
      </Datagrid>
    </List>
  )
}

export default ActivityList
