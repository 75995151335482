import React from 'react'
import { Edit } from 'react-admin'

import FullAccessAccountsForm from './FullAccessAccountsForm'

const FullAccessAccountsEdit = props => (
  <Edit undoable={false} {...props}>
    <FullAccessAccountsForm />
  </Edit>
)

export default FullAccessAccountsEdit
