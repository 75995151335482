import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  ReferenceField,
  Pagination,
  TextInput,
} from 'react-admin'

import CustomSwitchField from '../../components/CustomSwitchField'
import FilterComponent from '../../components/Filter'

const AudioSeriesPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 1000]} {...props} />
)

const AudioSeriesFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const AudioSeriesList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<AudioSeriesPagination />}
    filters={<AudioSeriesFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <CustomSwitchField
        resource="audio-series"
        label="Status"
        switchField="status"
      />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default AudioSeriesList
