import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'
import BookActionButton from '../../components/BookActionButton'
import BookStatusField from '../../components/BookStatusField'
import FilterComponent from '../../components/Filter'
import { ROLES } from '../../components/constants'

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const BookFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
    <ReferenceInput
      label="Publisher"
      source="publisherId"
      reference="users"
      filter={{ role: 'publisher' }}
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Author"
      source="authorId"
      reference="authors"
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
  </FilterComponent>
)

const ActionField = ({ record, permissions }) => {
  if (!record || permissions !== ROLES.ADMIN) return null

  switch (record.status) {
    case 'Under review':
      return (
        <React.Fragment>
          <BookActionButton
            label="Approve"
            action="approve"
            color="primary"
            record={record}
          />
          <BookActionButton
            label="Reject"
            action="reject"
            color="default"
            record={record}
          />
        </React.Fragment>
      )

    default:
      return <DeleteButton label="Delete" record={record} undoable={false} />
  }
}

const BookList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<BookFilter />}
      actions={<Actions />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField source="authorId" reference="authors" label="Author">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="publisherId"
          reference="users"
          label="Publisher">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="datePublished" label="Publish Date" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <BookStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
        <ActionField permissions={permissions} />
      </Datagrid>
    </List>
  )
}

export default BookList
