import React from 'react'
import { Create } from 'react-admin'

import CollectionForm from './CollectionCodeForm'

const CollectionCreate = props => (
  <Create {...props}>
    <CollectionForm />
  </Create>
)

export default CollectionCreate
