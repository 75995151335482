import { AppBar, UserMenu, MenuItemLink, Layout } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import React from 'react'

const MyUserMenu = props => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/changePassword"
      primaryText="Change Password"
      leftIcon={<SettingsIcon />}
    />
  </UserMenu>
)
const MyAppBar = props => (
  <AppBar color="primary" {...props} userMenu={<MyUserMenu />} />
)

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />

export default MyLayout
