import React, { useState } from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { BOOK_STATUSES } from './constants'
import get from 'lodash/get'

const BookStatusField = props => {
  const { record, dataProvider, disabled } = props
  const [status, setStatus] = useState(record.status)

  const handleChange = async () => {
    const data = {
      status:
        status === BOOK_STATUSES.PUBLISHED
          ? BOOK_STATUSES.UNPUBLISHED
          : BOOK_STATUSES.PUBLISHED,
    }

    const response = await dataProvider(
      UPDATE,
      'books',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Book updated', level: 'info' },
          redirectTo: '/books',
        },
        onFailure: {
          notification: {
            body: 'Error: books not updated',
            level: 'warning',
          },
        },
      },
    )
    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
    }
  }

  return [BOOK_STATUSES.PUBLISHED, BOOK_STATUSES.UNPUBLISHED].includes(
    record.status,
  ) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === BOOK_STATUSES.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default withDataProvider(BookStatusField)
