import React from 'react'
import { Create } from 'react-admin'

import CategoryGroupForm from './CategoryGroupForm'

const CategoryGroupCreate = props => (
  <Create {...props}>
    <CategoryGroupForm />
  </Create>
)

export default CategoryGroupCreate
