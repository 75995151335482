import React from 'react'
import { Edit } from 'react-admin'

import ConfigurationSettingForm from './ConfigurationSettingForm'

const ConfigurationSettingEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <ConfigurationSettingForm permissions={props.permissions} />
    </Edit>
  )
}

export default ConfigurationSettingEdit
