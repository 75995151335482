import React from 'react'

import {
  NumberInput,
  SimpleForm,
  SelectInput,
  DateInput,
  ReferenceInput,
  minValue,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const styles = {
  container: {
    width: 'unset',
  },
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const AffiliatePaymentsForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={8}>
          <ReferenceInput
            label="Affiliate"
            source="affiliateId"
            reference="users"
            filter={{ role: 'affiliate' }}
            alwaysOn
            allowEmpty
            validate={validateRequired('Affiliate')}>
            <SelectInput
              optionText="name"
              source="affiliateId"
              options={{
                fullWidth: true,
              }}
              required
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput
            label="Date"
            source="transferDate"
            alwaysOn
            fullWidth
            validate={validateRequired('Date')}
            required
            variant="standard"></DateInput>
        </Grid>

        <Grid item xs={12}>
          <NumberInput
            label="Amount"
            source="amount"
            fullWidth
            required
            variant="standard"
            validate={[validateRequired('Date'), minValue(0)]}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(AffiliatePaymentsForm)
