import React from 'react'
import { Edit } from 'react-admin'

import CollectionForm from './CollectionForm'

const CollectionEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CollectionForm editing />
    </Edit>
  )
}

export default CollectionEdit
