import React from 'react'
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
} from 'react-admin'

import {
  COMMISSION_EFFECTIVE_SCOPE_LABEL,
  COMMISSION_DURATION_TYPE_LABEL,
  COMMISSION_EFFECTIVE_SCOPE,
} from './constants'

const CustomEntityField = ({ record }) => {
  if (record && record.effectiveScope === COMMISSION_EFFECTIVE_SCOPE.BOOK) {
    return (
      <ReferenceField label="Book" source="bookId" reference="books">
        <TextField source="title" />
      </ReferenceField>
    )
  }
  if (
    record &&
    record.effectiveScope === COMMISSION_EFFECTIVE_SCOPE.PUBLISHER
  ) {
    return (
      <ReferenceField label="Publisher" source="publisherId" reference="users">
        <TextField source="name" />
      </ReferenceField>
    )
  }
  return '-'
}

const CommissionList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField label="Id" source="id" />
        <FunctionField
          label="Effective scope"
          source="effectiveScope"
          render={record =>
            COMMISSION_EFFECTIVE_SCOPE_LABEL[record.effectiveScope]
          }
        />
        <CustomEntityField label="Publisher/Book" />
        <FunctionField
          label="Duration type"
          source="durationType"
          render={record => COMMISSION_DURATION_TYPE_LABEL[record.durationType]}
        />
        {props.record && props.record.startDate ? (
          <DateField label="Start date" source="startDate" />
        ) : (
          <span label="Start date">-</span>
        )}
        {props.record && props.record.endDate ? (
          <DateField label="End date" source="endDate" />
        ) : (
          <span label="End date">-</span>
        )}
        <NumberField label="Commission" source="commission" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Approved by"
          source="approvedBy"
          reference="users">
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CommissionList
