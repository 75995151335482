import React from 'react'
import { SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const FullAccessAccountsForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput
            label="Username"
            source="userId"
            reference="users"
            filterToQuery={searchText => ({
              username: { $iLike: `%${searchText}%` },
            })}
            validate={validateRequired('user')}>
            <AutocompleteInput
              optionText="username"
              options={{
                fullWidth: true,
                disabled: props.editing ? true : false,
              }}
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default FullAccessAccountsForm
