import React from 'react'
import { Create } from 'react-admin'

import ReferralBookForm from './ReferralBookForm'

const ReferralBookCreate = props => (
  <Create {...props}>
    <ReferralBookForm />
  </Create>
)

export default ReferralBookCreate
