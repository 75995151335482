import React from 'react'
import { FunctionField } from 'react-admin'
import { ROLES } from '../constants'

const APPROVE_STATUS = {
  [ROLES.ADMIN]: 'Approved by Admin',
  [ROLES.STATISTICAL_STAFF]: 'Approved by Accounting',
  [ROLES.MARKETING_MANAGER]: 'Approved by Manager',
  [ROLES.HEAD_OF_CUSTOMER_SERVICE]: 'Approved by Head of CS',
}

const ApproveStatus = props => {
  return (
    <FunctionField
      {...props}
      render={record => {
        switch (record[props.statusField || 'status']) {
          case 'under_review':
            return 'Wait for approval'
          case 'approved':
            return APPROVE_STATUS[record.approveRole] || 'Approved'
          default:
            return 'Rejected'
        }
      }}
    />
  )
}

export default ApproveStatus
