import React, { Fragment } from 'react'
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  // minValue,
} from 'react-admin'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import Grid from '@material-ui/core/Grid'

const ManageSubscriptionCreate = props => {
  // const validateAmount = [minValue(0)]
  return (
    <Create undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput
              label="Username"
              source="userId"
              reference="users"
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
              })}
              validate={validateRequired('user')}>
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
          </Grid>

          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) return null
              const subscription = get(
                props.users[formData.userId],
                'subscription',
                null,
              )
              const isExpired =
                subscription &&
                subscription.endDate &&
                new Date() > new Date(subscription.endDate)
              if (formData.userId) {
                return (
                  <Fragment>
                    <Grid item xs={6}>
                      <TextInput
                        required
                        source="invoiceEmail"
                        fullWidth
                        variant="standard"
                        defaultValue={subscription && subscription.invoiceEmail}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {subscription && subscription.code && !isExpired ? (
                        <TextInput
                          source="code"
                          fullWidth
                          variant="standard"
                          defaultValue={subscription && subscription.code}
                          disabled
                        />
                      ) : (
                        <TextInput source="code" fullWidth variant="standard" />
                      )}
                    </Grid>
                    {subscription && !!subscription.amount && !isExpired && (
                      <Grid item xs={12}>
                        <TextInput
                          label="Amount"
                          source="amount"
                          fullWidth
                          variant="standard"
                          defaultValue={subscription && subscription.amount}
                          disabled
                        />
                      </Grid>
                    )}
                  </Fragment>
                )
              }
            }}
          </FormDataConsumer>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

const mapStateToProps = state => {
  return {
    users: state.admin.resources.users.data,
  }
}

export default connect(
  mapStateToProps,
  null,
)(ManageSubscriptionCreate)
