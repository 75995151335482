import React from 'react'

import {
  TextInput,
  ImageInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import EpisodeActionButton from '../../components/EpisodeActionButton'
import CustomAudioField from '../../components/CustomAudioField'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { makeStyles } from '@material-ui/core/styles'
import { EPISODE_STATUS } from '../../components/constants'

const styles = {}
const useStyles = makeStyles({
  button: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  actionButton: {
    position: 'absolute',
    right: '50px',
    marginTop: '0px',
  },
})

const EpisodeForm = ({ ...props }) => {
  const { ...rest } = props
  const classes = useStyles()

  return (
    <SimpleForm
      {...rest}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12} className={classes.actionButton}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) {
                return null
              }

              if (formData.status === EPISODE_STATUS.PUBLISHED) {
                return (
                  <>
                    <EpisodeActionButton
                      label="Disable"
                      action="disable"
                      color="secondary"
                      className={classes.button}
                      startIcon={<RemoveCircleOutlineIcon />}
                      record={formData}
                    />
                  </>
                )
              } else if (formData.status === EPISODE_STATUS.DISABLED) {
                return (
                  <EpisodeActionButton
                    label="Enable"
                    action="enable"
                    color="primary"
                    className={classes.button}
                    startIcon={<CheckCircleOutlineIcon />}
                    record={formData}
                  />
                )
              } else if (
                formData.status === EPISODE_STATUS.UNDER_REVIEW ||
                formData.status === EPISODE_STATUS.UNPUBLISHED
              ) {
                return (
                  <>
                    <EpisodeActionButton
                      label="Disable"
                      action="disable"
                      color="secondary"
                      className={classes.button}
                      startIcon={<RemoveCircleOutlineIcon />}
                      record={formData}
                    />
                  </>
                )
              }
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            source="episodeIndex"
            label="Episode Index"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="title"
            label="Episode name"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="description"
            label="Episode description"
            resettable
            minRows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAudioField {...rest} source="audioUrl" label="Audio file" />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(EpisodeForm)
