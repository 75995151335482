import React, { useMemo, useState } from 'react'
import { withDataProvider, UPDATE } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { debounce, get } from 'lodash'

const CustomSwitchField = props => {
  const { resource, switchField, record, dataProvider, disabled } = props

  const [checked, setChecked] = useState(get(record, switchField))

  const onChange = useMemo(() => {
    return debounce(
      checked =>
        dataProvider(
          UPDATE,
          resource,
          {
            id: record.id,
            data: {
              [switchField]: checked,
            },
          },
          {
            onSuccess: {
              notification: { body: 'Update successfully', level: 'info' },
              redirectTo: `/${resource}`,
            },
            onFailure: {
              notification: {
                body: `Error: ${resource} not updated`,
                level: 'warning',
              },
            },
          },
        ),
      500,
    )
  }, [])

  const handleChange = async () => {
    setChecked(prev => {
      onChange(!prev)
      return !prev
    })
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          disabled={
            typeof disabled === 'function' ? disabled(record) : disabled
          }
        />
      }
    />
  )
}

export default withDataProvider(CustomSwitchField)
