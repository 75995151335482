import React from 'react'
import Avatar from '@material-ui/core/Avatar'

const CustomAvatarField = props => {
  const { className, record, source, originalSrc = false } = props
  return (
    <Avatar
      src={
        record[source] ||
        (originalSrc ? record : `${process.env.REACT_APP_S3_URL}/${record}`)
      }
      alt={record[source] || record}
      className={`${className}`}
    />
  )
}

export const CustomS3AvatarField = props => {
  const { record, source } = props
  if (!record || !source) {
    return <></>
  }

  return <Avatar src={`${process.env.REACT_APP_S3_URL}/${record[source]}`} />
}

export default [CustomAvatarField, CustomS3AvatarField]
