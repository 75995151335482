import React from 'react'
import { Edit } from 'react-admin'

import LandingPageForm from './LandingPageForm'

const LandingPageEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <LandingPageForm permissions={props.permissions} />
    </Edit>
  )
}

export default LandingPageEdit
