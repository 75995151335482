import React from 'react'
import {
  List,
  Datagrid,
  EditButton,
  DeleteButton,
  ReferenceField,
  TextField,
  DateField,
} from 'react-admin'

import SlideshowStatusField from '../../components/SlideshowStatusField'
import { CustomS3ImageField } from '../../components/CustomImageField'

const AUDIO_TYPE_TITLE = {
  music: 'Music',
  book_summary: 'Book summary',
  meditation: 'Meditation',
  sleep_story: 'Sleep story',
  kids_story: 'Kids story',
}

const BookTextField = ({ record = {}, ...rest }) => {
  if (record.type !== 'book') return <span>-</span>

  return (
    <ReferenceField source="bookId" reference="books" record={record} {...rest}>
      <TextField source="title" />
    </ReferenceField>
  )
}

const AudioTypeTextField = ({ record = {} }) => {
  if (record.type !== 'audio_list') return <span>-</span>

  return <span>{AUDIO_TYPE_TITLE[record.audioType]}</span>
}

const AudioSeriesTextField = ({ record = {}, ...rest }) => {
  if (record.type !== 'audio_series') return <span>-</span>

  return (
    <ReferenceField
      source="audioSeriesId"
      reference="audio-series"
      record={record}
      {...rest}>
      <TextField source="name" />
    </ReferenceField>
  )
}

const SlideshowList = props => (
  <List {...props}>
    <Datagrid>
      <CustomS3ImageField source="url" />
      <BookTextField label="Book" />
      <AudioTypeTextField label="Audio type" />
      <AudioSeriesTextField label="Audio series" />

      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <SlideshowStatusField label="Status" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default SlideshowList
