import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import { Link, withRouter } from 'react-router-dom'
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import Button from '@material-ui/core/Button'
import compose from 'recompose/compose'

import { defaultTheme, Notification } from 'react-admin'
import LoginForm from './LoginForm'

const styles = theme =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  })

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
class Login extends Component {
  theme = createTheme(this.props.theme)
  containerRef = React.createRef()
  backgroundImageLoaded = false

  updateBackgroundImage = () => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      this.backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props

    if (backgroundImage) {
      const img = new Image()
      img.onload = this.updateBackgroundImage
      img.src = backgroundImage
    }
  }

  componentDidMount() {
    this.lazyLoadBackgroundImage()
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage()
    }
  }

  render() {
    const { classes, loginForm, ...rest } = this.props

    return (
      <MuiThemeProvider theme={this.theme}>
        <div className={classes.main} {...rest} ref={this.containerRef}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            {loginForm}

            <Button
              style={{ width: '100%' }}
              onClick={() => (window.location.href = '/#/forgot')}>
              <Link to="/forgot">Forgot password</Link>
            </Button>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    )
  }
}

const EnhancedLogin = withStyles(styles)(Login)

EnhancedLogin.defaultProps = {
  backgroundImage: '/background.jpg',
  theme: defaultTheme,
  loginForm: <LoginForm />,
}

export default compose(withRouter)(EnhancedLogin)
