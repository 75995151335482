import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  FunctionField,
  Pagination,
  TextInput,
  SelectInput,
} from 'react-admin'
import FilterComponent from '../../components/Filter'

const TOPIC_TYPE = {
  book: 'Book',
  english_book: 'English book',
  book_summary: 'Book summary',
  ebook: 'Ebook',
  kids_story: 'Kids story',
}

const TopicPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 1000]} {...props} />
)

const TopicFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: 'book', name: 'Book' },
        { id: 'english_book', name: 'English book' },
        { id: 'book_summary', name: 'Book summary' },
        { id: 'ebook', name: 'Ebook' },
      ]}
      alwaysOn
      allowEmpty={true}></SelectInput>
  </FilterComponent>
)

const TopicList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<TopicPagination />}
    filters={<TopicFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid>
      <TextField source="name" />
      <FunctionField source="type" render={record => TOPIC_TYPE[record.type]} />
      <TextField source="slug" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default TopicList
