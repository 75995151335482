import React from 'react'
import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  maxValue,
  minValue,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  withDataProvider,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { validateRequired } from '../utils/validate'
import { publisherTypeOptions } from '../resources/user/UserEdit'

const PeriodCommissionsInput = props => {
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateInput
                    label="Start date"
                    source={getSource('startDate')}
                    fullWidth
                    variant="standard"
                    required
                    validate={[validateRequired('Start date')]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateInput
                    label="End date"
                    source={getSource('endDate')}
                    fullWidth
                    variant="standard"
                    required
                    validate={[validateRequired('End date')]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumberInput
                    label="Commission"
                    source={getSource('commission')}
                    fullWidth
                    variant="standard"
                    required
                    classes={{ root: 'number-input-without-arrows' }}
                  />
                </Grid>
                {props.entity === 'user' ? (
                  <>
                    <Grid item xs={6}>
                      <SelectInput
                        label="Publisher type"
                        source={getSource('publisherType')}
                        choices={publisherTypeOptions}
                        required
                        fullWidth
                        variant="standard"
                        margin="normal"
                        validate={validateRequired('Publisher type')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {scopedFormData &&
                      scopedFormData.publisherType === '3' ? (
                        <NumberInput
                          fullWidth
                          variant="standard"
                          label="% Platform fee (0-100)"
                          source={getSource('platformFeePercentage')}
                          margin="normal"
                          validate={[
                            validateRequired('% Platform fee'),
                            minValue(0),
                            maxValue(100),
                          ]}
                          classes={{ root: 'number-input-without-arrows' }}
                        />
                      ) : null}
                      {scopedFormData &&
                      scopedFormData.publisherType === '4' ? (
                        <NumberInput
                          fullWidth
                          variant="standard"
                          label="% Tax fee (0-100)"
                          source={getSource('taxFeePercentage')}
                          margin="normal"
                          validate={[
                            validateRequired('% Tax fee'),
                            minValue(0),
                            maxValue(100),
                          ]}
                          classes={{ root: 'number-input-without-arrows' }}
                        />
                      ) : null}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default withDataProvider(PeriodCommissionsInput)
