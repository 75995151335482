import React from 'react'
import { Create } from 'react-admin'

import ApiCodeForm from './ApiCodeForm'

const ApiCodeCreate = props => (
  <Create {...props}>
    <ApiCodeForm />
  </Create>
)

export default ApiCodeCreate
