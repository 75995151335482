import React from 'react'
import { Create } from 'react-admin'

import CoachForm from './CoachForm'

const CoachCreate = props => (
  <Create {...props}>
    <CoachForm />
  </Create>
)

export default CoachCreate
