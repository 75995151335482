import React from 'react'
import { Create } from 'react-admin'

import UserCreditsForm from './UserCreditsForm'

const UserCreditsCreate = props => {
  const transform = data => {
    if (data.preset) {
      delete data.preset
    }
    return data
  }
  return (
    <Create {...props} transform={transform}>
      <UserCreditsForm />
    </Create>
  )
}

export default UserCreditsCreate
