import React from 'react'
import { Button, withDataProvider, UPDATE, useRefresh } from 'react-admin'

import { withStyles } from '@material-ui/core'

const styles = {
  button: { margin: '5px 0', minWidth: 90 },
  buttonReject: { backgroundColor: 'red' },
}

const CodActionButton = props => {
  const { action, classes, record, dataProvider, ...rest } = props
  const refresh = useRefresh()

  const handleClick = async () => {
    await dataProvider(
      UPDATE,
      'payment-cod',
      {
        id: record.id,
        data: action === 'confirm' ? { confirm: true } : { canceled: true },
      },
      {
        onSuccess: {
          notification: {
            body:
              action === 'confirm'
                ? `Confirmed COD payment`
                : 'Canceled COD payment',
            level: 'info',
          },
          redirectTo: '/payment-cod',
        },
        onFailure: {
          notification: {
            body:
              action === 'confirm'
                ? 'Error: COD payment not confirmed'
                : 'Error: COD payment not canceled',
            level: 'warning',
          },
        },
      },
    )

    refresh()
  }

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default withStyles(styles)(withDataProvider(CodActionButton))
