import React from 'react'
import { Create } from 'react-admin'

import TopicForm from './TopicForm'

const TopicCreate = props => (
  <Create {...props}>
    <TopicForm />
  </Create>
)

export default TopicCreate
