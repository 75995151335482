import React from 'react'
import { Create } from 'react-admin'

import FreeAccountsForm from './FreeAccountsForm'

const FreeAccountsCreate = props => (
  <Create {...props}>
    <FreeAccountsForm />
  </Create>
)

export default FreeAccountsCreate
