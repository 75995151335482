import React from 'react'
import { Button, useRefresh, useDataProvider, useNotify } from 'react-admin'

import { withStyles } from '@material-ui/core'

const styles = {
  button: { margin: '5px 0', minWidth: 90 },
  buttonReject: { backgroundColor: 'red' },
}

const BookActionButton = props => {
  const { classes, record, action, ...rest } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const getDataUpdate = () => {
    switch (action) {
      case 'approve':
        return {
          status: 'Published',
        }
      case 'reject':
        return {
          status: 'Rejected',
        }

      default:
        return {}
    }
  }

  const handleClick = async () => {
    const data = getDataUpdate()

    dataProvider
      .update('books', { id: record.id, data })
      .then(() => {
        notify('Book updated')
        refresh()
      })
      .catch(error => {
        notify(`Error: books not updated ${error.message}`, 'error')
      })
  }

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default withStyles(styles)(BookActionButton)
