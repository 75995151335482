import React from 'react'
import { Edit } from 'react-admin'

import MarketingCampaignForm from './MarketingCampaignForm'

const MarketingCampaignEdit = props => (
  <Edit undoable={false} {...props}>
    <MarketingCampaignForm />
  </Edit>
)

export default MarketingCampaignEdit
