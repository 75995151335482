import React, { useState, Fragment } from 'react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslate, useSafeSetState, useLogin, useNotify } from 'react-admin'
import useInterval from '../utils/useInterval'

const useStyles = makeStyles(
  theme => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      marginTop: '0.5rem',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' },
)

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const LoginForm = props => {
  const login = useLogin()
  const notify = useNotify()
  const classes = useStyles(props)
  const translate = useTranslate()
  const [verifyStep, setVerifyStep] = useState(false)
  const [resendTime, setResendTime] = useState(0)
  const [loading, setLoading] = useSafeSetState(false)

  const { redirectTo } = props
  useInterval(
    () => {
      setResendTime(resendTime - 1)
    },
    resendTime !== 0 ? 1000 : null,
  )

  const validate = values => {
    const errors = { username: undefined, password: undefined }

    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const handleLogin = values => {
    setLoading(true)
    login(values, redirectTo)
      .then(() => {})
      .catch(error => {
        if (
          error.message &&
          error.message.includes('Verification sent to email')
        ) {
          setVerifyStep(true)
          setResendTime(60)
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        )
      })
      .finally(() => setLoading(false))
  }

  const handleResend = values => {
    login(values, redirectTo)
      .then(() => {})
      .catch(error => {
        if (
          error.message &&
          error.message.includes('Verification sent to email')
        ) {
          setVerifyStep(true)
          setResendTime(60)
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        )
      })
      .finally(() => setLoading(false))
  }

  return (
    <Form
      onSubmit={handleLogin}
      validate={validate}
      render={props => {
        const { handleSubmit, values } = props
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              {verifyStep ? (
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="code"
                    name="code"
                    component={renderInput}
                    label="Verification code"
                    disabled={loading}
                  />
                </div>
              ) : (
                <Fragment>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      id="username"
                      name="username"
                      component={renderInput}
                      label={translate('ra.auth.username')}
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      id="password"
                      name="password"
                      component={renderInput}
                      label={translate('ra.auth.password')}
                      type="password"
                      disabled={loading}
                      autoComplete="current-password"
                    />
                  </div>
                </Fragment>
              )}
            </div>
            <CardActions style={{ flexDirection: 'column' }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}>
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate('ra.auth.sign_in')}
              </Button>
              {verifyStep && (
                <div style={{ marginTop: '1rem' }}>
                  <div>{`Didn't receive the verification code?`}</div>
                  <Button
                    className={classes.button}
                    onClick={() => handleResend(values)}
                    disabled={resendTime}>
                    {resendTime ? `Resend in ${resendTime} seconds` : 'Resend'}
                  </Button>
                </div>
              )}
            </CardActions>
            {verifyStep && (
              <Button
                onClick={() => setVerifyStep(false)}
                style={{ width: '100%' }}>
                Back
              </Button>
            )}
          </form>
        )
      }}
    />
  )
}

export default LoginForm
