import React from 'react'
import { Edit } from 'react-admin'
import PodcasterForm from './PodcasterForm'

const PodcasterEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <PodcasterForm permissions={props.permissions} />
    </Edit>
  )
}

export default PodcasterEdit
