import React, { Fragment, useCallback, useState, useEffect } from 'react'
import {
  List,
  SelectInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  withDataProvider,
  ReferenceInput,
  GET_ONE,
  AutocompleteInput,
  DateField,
  FunctionField,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FilterComponent from '../../components/Filter'
import { FILTER_DATE, ROLES, SUBSCRIPTION } from '../../components/constants'
import { formatCurrency } from '../../utils/formatCurrency'
import { exportExcel } from '../../utils/exportExcel'
import moment from 'moment'

const styles = {
  tabDetail: {
    display: 'flex',
    'flex-wrap': 'wrap',
    flex: 1,
    '&> div': {
      'min-width': '25%',
    },
  },
}

const exporter = records => {
  const data = records.map(record => {
    const { commission } = record
    const amountCommission =
      (commission / 100) * SUBSCRIPTION.YEARLY_PRICE * 0.9

    return {
      Code: record.code,
      Plan: record.productId.split('.').pop(),
      Discount: `${record.discount}%`,
      Price: record.amount,
      Commission: `${commission}%`,
      'Commission amount': amountCommission.toFixed(0),
      'Transaction ID': record.originalTransactionId,
      'Purchased at': new Date(record.createdAt).toLocaleDateString('vi-VN'),
      'Payment method': record.paymentMethod,
    }
  })

  exportExcel({
    data,
    title: `affiliate_dashboard_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Affiliate"
      source="affiliateId"
      reference="users"
      filterToQuery={searchText => ({
        name: { $iLike: `%${searchText}%` },
        role: ROLES.AFFILIATE,
      })}
      alwaysOn
      allowEmpty>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Filter"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomDatagrid = withStyles(styles)(
  withDataProvider(props => {
    const { dataProvider } = props

    const [isShowRevenue, toggleShowRevenue] = useState(false)
    const [state, setState] = useState({
      usersRedeemed: 0,
      newUsersRedeemed: 0,
      totalCommission: 0,
    })

    const PlanTextField = useCallback(({ record = {} }) => {
      const { productId } = record
      if (!productId) return <span>-</span>
      const plan = productId.split('.').pop()
      return <span>{plan}</span>
    }, [])

    const CommissionAmountField = useCallback(({ record = {} }) => {
      const { commission } = record
      let amountCommission =
        (commission / 100) * SUBSCRIPTION.YEARLY_PRICE * 0.9
      return <span>{formatCurrency.format(amountCommission.toFixed(0))}</span>
    }, [])

    useEffect(() => {
      dataProvider(GET_ONE, 'affiliate-dashboard-revenue', {
        id: null,
        date: props.filterValues && props.filterValues.date,
        affiliateId: props.filterValues && props.filterValues.affiliateId,
        fromDate: props.filterValues && props.filterValues.fromDate,
        toDate: props.filterValues && props.filterValues.toDate,
      })
        .then(({ data }) =>
          setState(v => ({ ...v, totalCommission: data.result })),
        )
        .catch(console.log)
      dataProvider(GET_ONE, 'affiliate-dashboard-install', {
        id: null,
        date: props.filterValues && props.filterValues.date,
        affiliateId: props.filterValues && props.filterValues.affiliateId,
        fromDate: props.filterValues && props.filterValues.fromDate,
        toDate: props.filterValues && props.filterValues.toDate,
      })
        .then(({ data }) => {
          setState(v => ({
            ...v,
            usersRedeemed: data.usersRedeemed,
            newUsersRedeemed: data.newUsersRedeemed,
            affiliate: data.affiliate,
          }))
        })
        .catch(console.log)
    }, [props.filterValues])

    return (
      <Fragment>
        <div style={{ marginLeft: 12 }}>
          <Grid container>
            {state.affiliate && (
              <Grid item xs={12}>
                <h3>Name: {state.affiliate.name}</h3>
                <h3>Web link: {state.affiliate.webLink}</h3>
              </Grid>
            )}
            <Grid item xs={12}>
              <h3>Users redeemed a free book: {state.usersRedeemed}</h3>
              <h3>New users redeemed a free book: {state.newUsersRedeemed}</h3>
            </Grid>
            {isShowRevenue ? (
              <>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    onClick={() => toggleShowRevenue(false)}>
                    Hide details
                  </Button>
                  <h3>
                    Total commission:{' '}
                    {formatCurrency.format(state.totalCommission)}
                  </h3>
                </Grid>
                <Datagrid {...props}>
                  <TextField source="code" />
                  <PlanTextField source="Plan" />
                  <FunctionField
                    label="Discount"
                    render={record => `${record.discount}%`}
                  />
                  <NumberField source="amount" label="Price (after discount)" />
                  <FunctionField
                    label="Commission"
                    render={record => `${record.commission}%`}
                  />
                  <CommissionAmountField
                    source="amount"
                    label="Commission amount"
                  />
                  <TextField
                    source="originalTransactionId"
                    label="Transaction Id"
                  />
                  <DateField source="createdAt" label="Purchased at" />
                  <TextField source="paymentMethod" label="Payment method" />
                </Datagrid>
              </>
            ) : (
              <Grid item xs={12}>
                <Button color="primary" onClick={() => toggleShowRevenue(true)}>
                  Show details
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Fragment>
    )
  }),
)

const AffiliateDashboard = props => (
  <List
    filters={<CustomFilter />}
    {...props}
    filterDefaultValues={{
      date: 'all',
    }}
    exporter={exporter}>
    <CustomDatagrid />
  </List>
)

export default AffiliateDashboard
