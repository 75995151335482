import React from 'react'
import { Edit } from 'react-admin'

import MeditationBackgroundForm from './MeditationBackgroundForm'

const MeditationBackgroundEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <MeditationBackgroundForm permissions={props.permissions} />
    </Edit>
  )
}

export default MeditationBackgroundEdit
