import React from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import Grid from '@material-ui/core/Grid'
import { ROLES } from '../../components/constants'
import CsDeleteAccountPreview from './CsDeleteAccountPreview'

const CsDeleteAccountCreate = props => {
  return (
    <Create undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput
              label="Username"
              source="userId"
              reference="users"
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
                role: ROLES.USER,
              })}
              validate={validateRequired('user')}>
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <FormDataConsumer>
              {({ formData }) =>
                formData && <CsDeleteAccountPreview userId={formData.userId} />
              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="reason"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Reason')}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsDeleteAccountCreate
