import React from 'react'

import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  SelectInput,
} from 'react-admin'

import Grid from '@material-ui/core/Grid'

import GridContainer from '../../components/GridContainer'

import { validateRequired } from '../../utils/validate'

const isAutoRenew = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }]

const FreeAccountsForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list" variant="standard" margin="normal">
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput
            label="Username"
            source="userId"
            reference="users"
            filterToQuery={searchText => ({
              username: { $iLike: `%${searchText}%` },
            })}
            validate={validateRequired('user')}>
            <AutocompleteInput
              optionText="username"
              options={{
                fullWidth: true,
                disabled: props.editing ? true : false,
              }}
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Is auto renew?"
            source="autoRenew"
            choices={isAutoRenew}
            fullWidth
            variant="standard"
            required
            defaultValue={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Influencer name"
            source="influencerName"
            required
            fullWidth
            variant="standard"
            validate={validateRequired('influencer name')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default FreeAccountsForm
