import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  FunctionField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { exportExcel } from '../../utils/exportExcel'
import CustomTextField from '../../components/CustomTextField'
import {
  CODE_TYPES,
  CODE_TYPE_NAME,
  FILTER_DATE,
} from '../../components/constants'
import FilterComponent from '../../components/Filter'

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Redeemed at':
        record.redeemedAt &&
        new Date(record.redeemedAt).toLocaleDateString('vi-VN'),
      'Redeemed user': record.redeemedUser && record.redeemedUser.username,
      'Source type': record.batchCodeId ? 'Batch code' : 'API code',
      Source: record.source,
      BatchId: record.batch_code && record.batch_code.batchId,
      Type: CODE_TYPE_NAME[record.type],
      Book: record.book && record.book.title,
    }
  })

  exportExcel({
    data,
    title: 'Redeemd codes',
    type: 'xlsx',
  })
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Username"
      source="userId"
      reference="users"
      alwaysOn
      filterToQuery={searchText => ({
        username: { $iLike: `%${searchText}%` },
      })}>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      label="Batch ID"
      source="batchId"
      reference="batch-codes"
      alwaysOn
      filterToQuery={searchText => ({
        batchId: searchText,
      })}>
      <AutocompleteInput optionText="batchId" />
    </ReferenceInput>
    <SelectInput
      source="type"
      choices={CODE_TYPES}
      alwaysOn
      allowEmpty={true}
    />
    <SelectInput
      source="source"
      choices={[{ id: 'web', name: 'Website' }, { id: 'tiki', name: 'Tiki' }]}
      alwaysOn
      allowEmpty={true}
    />
    <SelectInput
      label="Redeemed Date"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}
    />
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const RedeemedCodeList = props => (
  <List
    {...props}
    filters={<CustomFilter />}
    exporter={exporter}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <DateField source="redeemedAt" />
      <CustomTextField
        label="Redeemed user"
        source="userId"
        reference="users"
        referenceField="username"
        {...props}
      />
      <FunctionField
        label="Source type"
        source="batchCodeId"
        render={record => (record.batchCodeId ? 'Batch code' : 'API code')}
      />
      <TextField source="source" label="Source" />
      <CustomTextField
        label="Batch ID"
        source="batchCodeId"
        reference="batch-codes"
        referenceField="batchId"
        {...props}
      />
      <FunctionField
        source="type"
        render={record => CODE_TYPE_NAME[record.type]}
      />
      <CustomTextField
        label="Book"
        source="bookId"
        reference="books"
        referenceField="title"
        {...props}
      />
    </Datagrid>
  </List>
)

export default RedeemedCodeList
