import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ExportButton,
  NumberField,
} from 'react-admin'

const Actions = ({ resource, currentSort, filterValues, exporter }) => {
  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  )
}

const AffiliatePaymentsList = props => (
  <List
    {...props}
    title="Payment history"
    bulkActionButtons={false}
    actions={<Actions />}>
    <Datagrid>
      <TextField source="id" />
      <DateField source="transferDate" />
      <NumberField source="amount" />
    </Datagrid>
  </List>
)

export default AffiliatePaymentsList
