import React, { memo, useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import UserDetailSummary from '../user-details/UserDetailsShow'

const styles = {
  drawer: { padding: 24, minWidth: 1024 },
}

const CsDeleteAccountPreview = ({ userId, classes }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {userId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}>
          <Typography variant="caption">SHOW USER PREVIEW</Typography>
        </Button>
      )}
      <SwipeableDrawer
        anchor="right"
        open={open}
        onBackdropClick={() => setOpen(false)}>
        <Container className={classes.drawer}>
          <Box component="div">
            <Typography variant="h5">Delete user: </Typography>
            {userId && (
              <UserDetailSummary
                id={userId}
                basePath="/user-details-1"
                resource="user-details"
                hasEdit={false}
                tabSyncWithLocation={false}
              />
            )}
          </Box>
        </Container>
      </SwipeableDrawer>
    </>
  )
}

export default withStyles(styles)(memo(CsDeleteAccountPreview))
