import React from 'react'

import {
  TextInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  maxLength,
} from 'react-admin'

import Grid from '@material-ui/core/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'

import { validateRequired, validateUnique } from '../../utils/validate'
import { CHALLENGE_REWARD_TYPES } from '../../components/constants'

const CollectionForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <TextInput source="name" fullWidth variant="standard" />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="type"
            fullWidth
            variant="standard"
            validate={[
              validateRequired('type'),
              maxLength(255, 'Do not enter more than 255 characters'),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ArrayInput
            label="Items"
            source="items"
            fullWidth
            validate={[
              validateRequired('Items'),
              validateUnique('entityId', true),
              validateUnique('order', true),
            ]}>
            <SimpleFormIterator>
              <NumberInput
                source="order"
                label="Order"
                variant="standard"
                fullWidth
              />
              <SelectInput
                label="Type"
                source="type"
                choices={CHALLENGE_REWARD_TYPES}
                fullWidth
                variant="standard"
                allowEmpty={false}
              />
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  if (!scopedFormData || !getSource || !scopedFormData.type) {
                    return null
                  }
                  return (
                    <ReferenceInput
                      label={
                        CHALLENGE_REWARD_TYPES.find(
                          ({ id }) => id === scopedFormData.type,
                        ).name
                      }
                      source={getSource('entityId')}
                      reference={
                        CHALLENGE_REWARD_TYPES.find(
                          ({ id }) => id === scopedFormData.type,
                        ).entity
                      }
                      rows={6}
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                      })}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default CollectionForm
