import React from 'react'
import { Create } from 'react-admin'

import PodcasterForm from './ChannelForm'

const ChannelCreate = props => (
  <Create {...props}>
    <PodcasterForm isCreating permissions={props.permissions} />
  </Create>
)

export default ChannelCreate
