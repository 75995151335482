import React from 'react'
import {
  List,
  SelectInput,
  DateInput,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  TextField,
  withDataProvider,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'

const styles = {
  tabDetail: {
    display: 'flex',
    'flex-wrap': 'wrap',
    flex: 1,
    '&> div': {
      'min-width': '25%',
    },
  },
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      label="Filter"
      source="date"
      choices={FILTER_DATE}
      alwaysOn
      allowEmpty={false}></SelectInput>
    {'custom' === props.filterValues.date && (
      <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
    )}
    {'custom' === props.filterValues.date && (
      <DateInput label="To date" source="toDate" alwaysOn></DateInput>
    )}
  </FilterComponent>
)

const CustomDatagrid = withStyles(styles)(
  withDataProvider(props => {
    const { data, basePath, resource, classes } = props

    return (
      <TabbedShowLayout
        {...{
          basePath,
          resource,
          record: {
            user: data.user,
            subscription: data.subscription,
            content: data.content,
          },
        }}>
        <Tab label="User">
          <SimpleShowLayout className={classes.tabDetail}>
            <TextField label="New users" source="user.data.newUsers" />
            <TextField label="Total users" source="user.data.totalUsers" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Subscription">
          <SimpleShowLayout className={classes.tabDetail}>
            <TextField
              label="Active month subscriptions"
              source="subscription.data.monthActive"
            />
            <TextField
              label="Active annual subscriptions"
              source="subscription.data.annualActive"
            />
            <TextField
              label="Total active subscriptions"
              source="subscription.data.totalActive"
            />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Content">
          <SimpleShowLayout className={classes.tabDetail}>
            <TextField label="New audiobooks" source="content.data.newBooks" />
            <TextField
              label="Total audiobooks"
              source="content.data.totalBooks"
            />
            <TextField
              label="New book summaries"
              source="content.data.newBookSummaries"
            />
            <TextField
              label="Total book summaries"
              source="content.data.totalBookSummaries"
            />
            <TextField
              label="New meditations"
              source="content.data.newMeditations"
            />
            <TextField
              label="Total meditations"
              source="content.data.totalMeditations"
            />
            <TextField
              label="New sleep stories"
              source="content.data.newSleepStories"
            />
            <TextField
              label="Total sleep stories"
              source="content.data.totalSleepStories"
            />
            <TextField label="New musics" source="content.data.newMusics" />
            <TextField label="Total musics" source="content.data.totalMusics" />
            <TextField
              label="New kids stories"
              source="content.data.newKidsStories"
            />
            <TextField
              label="Total kids stories"
              source="content.data.totalKidsStories"
            />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    )
  }),
)

const KpiDashboard = props => (
  <List
    filters={<CustomFilter />}
    {...props}
    exporter={false}
    filterDefaultValues={{
      date: 'all',
    }}
    pagination={null}>
    <CustomDatagrid />
  </List>
)

export default KpiDashboard
