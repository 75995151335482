import React from 'react'
import { Create } from 'react-admin'

import PodcasterForm from './EpisodeForm'

const EpisodeCreate = props => (
  <Create {...props}>
    <PodcasterForm isCreating permissions={props.permissions} />
  </Create>
)

export default EpisodeCreate
