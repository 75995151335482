import React from 'react'
import { Button, useRefresh, useDataProvider, useNotify } from 'react-admin'
import { EPISODE_STATUS } from './constants'
import { withStyles } from '@material-ui/core'

const styles = {
  button: { margin: '5px 0' },
}

const EpisodeActionButton = props => {
  const { classes, record, action, ...rest } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  console.log(record)

  const getDataUpdate = () => {
    switch (action) {
      case 'disable':
        return {
          status: EPISODE_STATUS.DISABLED,
        }
      case 'enable':
        return {
          status: EPISODE_STATUS.UNPUBLISHED,
        }
      case 'published':
        return {
          status: EPISODE_STATUS.PUBLISHED,
        }
      case 'unpublished':
        return {
          status: EPISODE_STATUS.UNPUBLISHED,
        }
      default:
        return {}
    }
  }

  const handleClick = async () => {
    const data = getDataUpdate()

    dataProvider
      .update('episodes', { id: record.id, data })
      .then(() => {
        notify('Episode updated')
        refresh()
      })
      .catch(error => {
        notify(`Error: Episode not updated ${error.message}`, 'error')
      })
  }

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      {...rest}></Button>
  )
}

export default withStyles(styles)(EpisodeActionButton)
