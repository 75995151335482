import React from 'react'

import { TextInput, SimpleForm, DateInput, SelectInput } from 'react-admin'

import Grid from '@material-ui/core/Grid'

import GridContainer from '../../components/GridContainer'

import BookToolbar from '../../components/BookToolbar'

const MarketingCampaignForm = props => {
  const TYPES = [
    {
      id: 'subscriptions_discount',
      name: 'Subscription Discount',
      disabled: props.editing,
    },
    {
      id: 'credit_bundle_discount',
      name: 'Credit Bundle Discount',
      disabled: props.editing,
    },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <SelectInput
            source="type"
            choices={TYPES}
            fullWidth
            variant="standard"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="name" fullWidth variant="standard" />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="promotionProductId"
            fullWidth
            label="Product Id"
            variant="standard"
            required
          />
        </Grid>

        <Grid item xs={12}>
          <DateInput label="Start date" source="startDate" alwaysOn></DateInput>
        </Grid>
        <Grid item xs={12}>
          <DateInput label="End date" source="endDate" alwaysOn></DateInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default MarketingCampaignForm
