import React from 'react'
import { Edit } from 'react-admin'
import BannerForm from './BannerForm'

const BannerEdit = props => (
  <Edit undoable={false} {...props}>
    <BannerForm />
  </Edit>
)

export default BannerEdit
