import React from 'react'
import { Create } from 'react-admin'

import AuthorForm from './AuthorForm'

const AuthorCreate = props => (
  <Create {...props}>
    <AuthorForm />
  </Create>
)

export default AuthorCreate
