import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Title,
  TextInput,
  SimpleForm,
  CREATE,
  showNotification,
  Notification,
} from 'react-admin'
import { validateRequired } from '../utils/validate'
import getDataProvider from '../dataProvider'
import { connect } from 'react-redux'
import qs from 'query-string'
import compose from 'recompose/compose'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url('https://source.unsplash.com/random/1600x900/daily')`,
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
  })

const ResetPasswordPage = props => {
  const handleSubmit = data => {
    const { showNotification } = props
    return (
      getDataProvider()(CREATE, 'authentication/resetPassword', {
        data: {
          token: qs.parse(props.location.search, { ignoreQueryPrefix: true })
            .token,
          password: data.password,
        },
      })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          window.location.href = '/#/login'
          showNotification('Change password successfully.')
        })
        .catch(error => {
          showNotification(error.message, 'warning')
        })
    )
  }

  const { classes } = props

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Title title="Reset Password" />
        <CardContent>
          <SimpleForm save={handleSubmit}>
            <TextInput
              label="New Password"
              source="password"
              type="password"
              validate={validateRequired('Password')}
            />
          </SimpleForm>
        </CardContent>
      </Card>
      <Notification />
    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(
    null,
    { showNotification },
  ),
)(ResetPasswordPage)
