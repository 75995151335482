import React, { Children, cloneElement, isValidElement, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { useInput, useTranslate } from 'ra-core'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Button, Labeled } from 'react-admin'
import FileInputPreview from './FileInputPreview'
import sanitizeRestProps from './sanitizeRestProps'
import {
  removeFile,
  uploadAttachment,
  uploadChapter,
  uploadEpisode,
  uploadFileSample,
  uploadGuidedMeditationSession,
  uploadIntroVideo,
  uploadSubtitle,
  uploadTeaser,
} from '../uploadFileCapable'
import { get } from 'lodash'
import ReplayIcon from '@material-ui/icons/Replay'
import DoneIcon from '@material-ui/icons/Done'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const UPLOAD_FUNC = {
  chapter: uploadChapter,
  attachment: uploadAttachment,
  subtitle: uploadSubtitle,
  'meditation-session': uploadGuidedMeditationSession,
  episode: uploadEpisode,
  introVideo: uploadIntroVideo,
  fileSample: uploadFileSample,
  teaser: uploadTeaser,
}

const useStyles = makeStyles(
  () => ({
    dropZone: {
      background: '#efefef',
      cursor: 'pointer',
      padding: '1rem',
      textAlign: 'center',
      color: '#999',
      width: '100%',
      height: '69px',
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' },
)

const FileInput = props => {
  const {
    accept,
    children,
    className,
    // classes: classesOverride,
    format,
    label,
    // labelMultiple = 'ra.input.file.upload_several',
    // labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    // placeholder,
    resource,
    source,
    validate,
    type,
    upload,
    showProgress,
    helperText,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const [uploadedStatus, setUploadedStatus] = useState(true)
  const [uploadProgress, setUploadProgress] = useState({})

  // turn a browser dropped file structure into expected structure
  const transformFile = file => {
    if (!(file instanceof File)) {
      return file
    }

    const { source, title } = Children.only(children).props

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    }

    if (title) {
      transformedFile[title] = file.name
    }

    return transformedFile
  }

  const transformFiles = files => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  })

  const files = value ? (Array.isArray(value) ? value : [value]) : []

  const onDrop = async (newFiles, rejectedFiles, event) => {
    setUploadedStatus(false)
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    if (multiple) {
      onChange(updatedFiles)
    } else {
      onChange(updatedFiles[0])
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event)
    }

    if (upload) {
      // eslint-disable-next-line no-unused-vars
      for (const idx in updatedFiles) {
        await uploadFile(updatedFiles[idx], idx)
      }
    }

    setUploadedStatus(true)
  }

  const uploadFile = async (file, index) => {
    try {
      const uploadFunc = UPLOAD_FUNC[type]
      // eslint-disable-next-line require-atomic-updates
      file[source.split('.').slice(-1)[0]] = await uploadFunc(
        file,
        progressEvent => {
          setUploadProgress({
            ...uploadProgress,
            [index]: progressEvent,
          })
        },
      )
    } catch (e) {
      console.log('Upload failed error', index, file, e)
      setUploadProgress({
        ...uploadProgress,
        [index]: {
          loaded: -1,
        },
      })
    }
  }

  const onRemove = file => () => {
    if (multiple) {
      const filteredFiles = files.filter(
        stateFile => !shallowEqual(stateFile, file),
      )
      onChange(filteredFiles)
    } else {
      onChange(null)
    }

    if (options.onRemove) {
      options.onRemove(file)
    }

    const s3Url = get(file, 'rawFile.S3Url.url', null)
    if (s3Url) {
      removeFile(s3Url)
    }
  }

  const bytesToMB = bytes => {
    return (bytes / (1024 * 1024)).toFixed(2)
  }

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? Children.only(children)
      : undefined

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  })

  const renderProgress = (file, index, progress) => {
    if (progress.loaded >= progress.total) {
      return (
        <>
          <span style={{ color: '#22bb33', marginRight: 5 }}>
            Upload success
          </span>
          <span style={{ color: '#22bb33' }}>
            <DoneIcon />
          </span>
        </>
      )
    }

    if (progress.loaded === -1) {
      return (
        <>
          <Typography color="secondary">Upload failed</Typography>
          <Box ml={2} />
          <Button
            color={'secondary'}
            variant="contained"
            label="Retry"
            onClick={() => uploadFile(file.rawFile, index)}>
            <ReplayIcon />
          </Button>
        </>
      )
    }

    return (
      <span
        style={{
          color: '#5bc0de',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
        }}>
        Uploading:
        <span style={{ marginLeft: 5, fontWeight: 700 }}>
          {bytesToMB(progress.loaded)} / {bytesToMB(progress.total)} MB
        </span>
      </span>
    )
  }

  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      {...sanitizeRestProps(rest)}>
      <>
        <div
          data-testid="dropzone"
          className={classes.dropZone}
          {...getRootProps()}>
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {label}
        </div>
        {meta && meta.touched && meta.error && (
          <FormHelperText>{translate(meta.error)}</FormHelperText>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {children && (
          <div className="previews">
            {files.map((file, index) => {
              const progress = uploadProgress[index]
              return (
                <FileInputPreview
                  key={index}
                  file={file}
                  onRemove={onRemove(file)}
                  className={classes.removeButton}>
                  {cloneElement(childrenElement, {
                    record: file,
                    className: classes.preview,
                  })}
                  {showProgress ? (
                    progress ? (
                      <Box ml={2} display="flex" alignItems="center">
                        {renderProgress(file, index, progress)}
                      </Box>
                    ) : null
                  ) : (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: '1rem',
                        height: '1rem',
                        marginLeft: '0.5rem',
                        display: uploadedStatus ? 'none' : 'inline-block',
                      }}
                    />
                  )}
                </FileInputPreview>
              )
            })}
          </div>
        )}
      </>
    </Labeled>
  )
}

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
  showProgress: PropTypes.bool,
}

export default FileInput
