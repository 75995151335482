import React from 'react'

import {
  TextInput,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SimpleForm,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
  minValue,
  maxValue,
  FormDataConsumer,
} from 'react-admin'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'

import { validateRequired, validateUnique } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'
import CustomFileInput from '../../components/CustomFileInput'

const styles = {
  totalPrice: {
    marginTop: 10,
    fontWeight: 'bold',
  },
}

const validateChapterOrder = [
  validateRequired('Order'),
  minValue(1),
  maxValue(1000),
]

const isFree = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }]
const statuses = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }]

const BookSummaryForm = ({ permissions, ...props }) => {
  const allowPermission = [
    ROLES.ADMIN,
    ROLES.CPSO,
    ROLES.MARKETING_MANAGER,
    ROLES.CONTENT_EDITOR,
  ].includes(permissions)
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Title')]}
          />
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            label="Related Book"
            source="relatedBookId"
            reference="books"
            alwaysOn
            filterToQuery={searchText => ({
              title: { $iLike: `%${searchText}%` },
            })}
            allowEmpty>
            <AutocompleteInput
              optionText="title"
              fullWidth
              variant="standard"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            label="Related Ebook"
            source="relatedEbookId"
            reference="ebooks"
            filterToQuery={searchText => ({
              title: { $iLike: `%${searchText}%` },
            })}
            allowEmpty>
            <AutocompleteInput
              optionText="title"
              fullWidth
              variant="standard"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={allowPermission ? 6 : 12}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free')}
          />
        </Grid>

        {allowPermission && (
          <Grid item xs={6}>
            <SelectInput
              label="Publish"
              source="status"
              choices={statuses}
              default={false}
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Publish')}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <ReferenceInput
            label="Primary category"
            source="primaryCategoryId"
            reference="categories"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: 'book_summary',
              primary: true,
            })}
            validate={validateRequired('Primary category')}>
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={8}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.primaryCategoryId) return null
              return (
                <ReferenceArrayInput
                  label="Secondary categories"
                  source="secondaryCategoryIds"
                  reference="categories"
                  filterToQuery={searchText => ({
                    name: { $iLike: `%${searchText}%` },
                    type: 'book_summary',
                    primary: false,
                    parentId: formData.primaryCategoryId,
                  })}>
                  <AutocompleteArrayInput
                    optionText="name"
                    fullWidth
                    required
                    variant="standard"
                  />
                </ReferenceArrayInput>
              )
            }}
          </FormDataConsumer>
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Topics"
            source="topicIds"
            reference="topics"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: 'book_summary',
            })}>
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Authors"
            source="authorIds"
            reference="authors"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
            })}>
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            validate={validateRequired('Description')}
            multiline
          />
        </Grid>

        {/* <Grid item xs={12}>
          <MarkdownInput source="text" validate={validateText} label="Text" />
        </Grid> */}

        <Grid item xs={12}>
          <ArrayInput
            source="audiences"
            label="Who's it for"
            fullWidth
            variant="standard"
            validate={[validateRequired("Who's it for")]}>
            <SimpleFormIterator>
              <TextInput
                fullWidth
                variant="standard"
                source="audience"
                label="Text"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <ArrayInput
          source="chapters"
          fullWidth
          validate={[
            validateRequired('Chapters'),
            validateUnique('order', true),
          ]}>
          <SimpleFormIterator>
            <TextInput
              source="order"
              label="Order"
              variant="standard"
              validate={validateChapterOrder}
            />
            <TextInput
              source="name"
              label="Name"
              fullWidth
              variant="standard"
              validate={validateRequired('name')}
            />
            <TextInput
              source="text"
              label="Text"
              resettable
              rows={8}
              fullWidth
              variant="standard"
              validate={validateRequired('Chapter text')}
              multiline
            />
            <CustomFileInput
              source="url"
              label="File"
              type="chapter"
              accept="audio/*"
              upload={true}
              validate={validateRequired('Chapter file')}>
              <CustomFileField
                source="src"
                title="chapter"
                variant="standard"
                canDownload={permissions === ROLES.ADMIN}
              />
            </CustomFileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </GridContainer>
    </SimpleForm>
  )
}

export default withStyles(styles)(BookSummaryForm)
